import ResolverManager from '../'
import ResolveResult from './ResolveResult'

export default abstract class Resolver {
  private _nextResolver?: Resolver
  protected _pathQuery?: {[key: string]: string}
  set pathQuery (query: {[key: string]: string}) {
    this._pathQuery = query
  }

  set nextResolver (resolver: Resolver) {
    this._nextResolver = resolver
  }

  constructor (resolverManager: ResolverManager) {
    resolverManager.addResolver(this)
  }

  abstract resolvePath (): Promise<ResolveResult>;

  async resolve (): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      try {
        const resolveResult = await this.resolvePath()

        if (resolveResult.isResolved) {
          return Promise.resolve(resolveResult)
        } else {
          if (this._nextResolver !== undefined) {
            const nextResolveResult = await this._nextResolver.resolve()
            return Promise.resolve(nextResolveResult)
          }

          return Promise.resolve({ isResolved: false })
        }
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.reject(new Error('No path query available'))
  }
}
