import { defineComponent } from 'vue'
import { Button as AButton } from 'ant-design-vue'
import { ButtonColorType } from '../utils'

export default defineComponent({
  name: 'CommonButton',
  components: { AButton },
  props: {
    type: {
      required: false,
      type: String,
      default: 'default'
    },
    size: {
      required: false,
      type: String,
      default: 'default'
    },
    width: {
      required: false,
      type: String,
      default: '275px'
    },
    colorType: {
      required: false,
      type: Number
    }
  },
  setup() {
    return {
      ButtonColorType
    }
  }
})
