function getURLComponent(url: string, componentName: string): string | undefined {
  const urlRegex = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/
  const result = urlRegex.exec(url)
  if (result) {
    switch (componentName) {
      case 'host':
        return result[3]
      case 'path':
        return result[5]
      case 'query':
        return result[6]
      case 'hash':
        return result[7]
    }
  }

  return undefined
}

export { getURLComponent }
