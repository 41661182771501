import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import { userShareInfo } from '@/views/app/utils/path-resolver/api'
import ShareGuestType from '@/views/share-get/models/ShareGuestType'
import { CouponError } from '@/network/error-code'

export default class SharePathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    const { shareCode } = this._pathQuery || {}

    if (shareCode !== undefined) {
      try {
        const code = decodeURIComponent(shareCode)
        const { data } = await userShareInfo(code)
        const { newGuestSharedInfo, oldGuestSharedInfo, shareGuestType } = data

        if (!newGuestSharedInfo && !oldGuestSharedInfo) {
          if (shareGuestType === ShareGuestType.NewCustomer) {
            return Promise.reject(new Error('couponAvailableToReturn'))
          } else if (shareGuestType === ShareGuestType.ReturnedCustomer) {
            return Promise.reject(new Error('couponAvailableToNew'))
          }
        }

        return Promise.resolve({ isResolved: true, pathName: 'shareGet', params: { shareInfo: JSON.stringify(data), shareCode: code } })
      } catch (error) {
        return Promise.reject(new Error((error as { code: number }).code === CouponError.ShareLinkExpired ? 'shareLinkExpired'
          : ((error as { code: number }).code === CouponError.ShareLinkInvalid ? 'shareLinkInvalid' : 'requestFail')))
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
