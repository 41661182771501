import { createApp } from 'vue'
import App from './views/app/index.vue'
import router from './router'
import i18n from './i18n'
import lazyPlugin from 'vue3-lazy'
import { message } from 'ant-design-vue'
import initializeFirebaseApp from '@/firebase/firebase-init'
import FirebaseSignInManager from '@/firebase/FirebaseSignInManager'
import ManageToken from '@/network/utils/manage-token'
import { getAuth } from 'firebase/auth'
import PageLayout from '@/components/page-layout/index.vue'
import VueGtag from 'vue-gtag-next'

function mountApp() {
  const app = createApp(App)
  app.use(router) // app use vue router
  app.use(i18n) // app config i18n
  app.use(VueGtag, {
    property: {
      id: 'G-GSJJZR5H2H'
    }
  })
  app.use(lazyPlugin, {
    loading: require('@/assets/image/item_placeholder.png'),
    error: require('@/assets/image/item_placeholder.png')
  })
  app.mount('#app') // Everything done, mount app with no error

  app.component('page-layout', PageLayout)
}

initializeFirebaseApp() // initialize firebase
getAuth()

ManageToken().then(() => { // Waiting for access token initialized
  const firebaseSignInManager = FirebaseSignInManager.instance()
  firebaseSignInManager.callbacks = {
    isRunOnce: true,
    completed: () => mountApp(),
    error: e => message.error(`authorized failed, message: ${e.message}, code: ${e.code}`)
  }
  firebaseSignInManager.addAuthObserver()
})
