enum LangType {
  English = 0,
  Chinese,
  Spanish
}

type Action = <T = unknown, R = unknown>(args?: T) => R
type LangOptionsType = {
  value: number
  text: string
}

function langOptionArr (t: Action): LangOptionsType[] {
  return [
    {
      value: LangType.English,
      text: t('english')
    },
    {
      value: LangType.Chinese,
      text: t('chinese')
    },
    {
      value: LangType.Spanish,
      text: t('spanish')
    }
  ]
}

export { LangType, langOptionArr, LangOptionsType }
