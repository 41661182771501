enum OrderMenuType {
  Invalid,
  Online,
  DineIn,
  TableFast,
  NoTableFast,
  ViewMenu,
  Menu,
  WaitlistMenu,
  ShareMenu,
  ScanToPay
}

export default OrderMenuType
