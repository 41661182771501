import ShopAutoId from '@/utils/firebase-storage-manager/models/ShopAutoId'
import Keys from './Keys'

export default class LocalStorageManager {
  private static _save (key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  private static _fetch (key: string): string {
    return localStorage.getItem(key) ?? ''
  }

  private static _saveObject<T> (key: string, value: T): void {
    const json = JSON.stringify(value)
    localStorage.setItem(key, json)
  }

  private static _fetchObject<T> (key: string): T | undefined {
    const json = localStorage.getItem(key) ?? ''

    if (json !== '') {
      try {
        return JSON.parse(json)
      } catch (e) {
        localStorage.removeItem(key)
      }
    }

    return undefined
  }

  clear(): void {
    localStorage.clear()
  }

  // removeOrders(): void {
  //   localStorage.removeItem(Keys.USER_ONLINE_ORDERS)
  //   localStorage.removeItem(Keys.USER_FAST_ORDERS)
  //   localStorage.removeItem(Keys.USER_WAITLISTS)
  //   localStorage.removeItem(Keys.USER_DINE_IN_ORDERS)
  // }

  removeLoginInfo(): void {
    localStorage.removeItem(Keys.ACCESS_TOKEN)
    localStorage.removeItem(Keys.REFRESH_TOKEN)
    localStorage.removeItem(Keys.EXPIRES)
    localStorage.removeItem(Keys.USER_ID)
    localStorage.removeItem(Keys.USER_NAME_PHONE)
  }

  set accessToken(token: string) {
    LocalStorageManager._save(Keys.ACCESS_TOKEN, token)
  }

  get accessToken(): string {
    return LocalStorageManager._fetch(Keys.ACCESS_TOKEN)
  }

  set refreshToken(token: string) {
    LocalStorageManager._save(Keys.REFRESH_TOKEN, token)
  }

  get refreshToken(): string {
    return LocalStorageManager._fetch(Keys.REFRESH_TOKEN)
  }

  set expires(tokenExpire: number) {
    LocalStorageManager._save(Keys.EXPIRES, `${tokenExpire}`)
  }

  get expires(): number {
    const tokenExpire = LocalStorageManager._fetch(Keys.EXPIRES)

    if (tokenExpire === '') {
      return -1
    }

    return Number(tokenExpire)
  }

  set userId(userId: string) {
    LocalStorageManager._save(Keys.USER_ID, userId)
  }

  get userId(): string {
    return LocalStorageManager._fetch(Keys.USER_ID)
  }

  set userNamePhone(nameAndPhone: { name: string, phone: string }) {
    LocalStorageManager._saveObject(Keys.USER_NAME_PHONE, nameAndPhone)
  }

  get userNamePhone(): { name: string, phone: string } {
    return LocalStorageManager._fetchObject(Keys.USER_NAME_PHONE) ?? { name: '', phone: '' }
  }

  set userDineInOrders(dineInOrders: ShopAutoId[]) {
    LocalStorageManager._saveObject(Keys.USER_DINE_IN_ORDERS, dineInOrders)
  }

  get userDineInOrders(): ShopAutoId[] {
    return LocalStorageManager._fetchObject<ShopAutoId[]>(Keys.USER_DINE_IN_ORDERS) ?? []
  }

  set userWaitlists(waitlists: ShopAutoId[]) {
    LocalStorageManager._saveObject(Keys.USER_WAITLISTS, waitlists)
  }

  get userWaitlists(): ShopAutoId[] {
    return LocalStorageManager._fetchObject<ShopAutoId[]>(Keys.USER_WAITLISTS) ?? []
  }

  set userFastOrders(fastOrders: ShopAutoId[]) {
    LocalStorageManager._saveObject(Keys.USER_FAST_ORDERS, fastOrders)
  }

  get userFastOrders(): ShopAutoId[] {
    return LocalStorageManager._fetchObject<ShopAutoId[]>(Keys.USER_FAST_ORDERS) ?? []
  }

  set userOnlineOrders(onlineOrders: ShopAutoId[]) {
    LocalStorageManager._saveObject(Keys.USER_ONLINE_ORDERS, onlineOrders)
  }

  get userOnlineOrders(): ShopAutoId[] {
    return LocalStorageManager._fetchObject<ShopAutoId[]>(Keys.USER_ONLINE_ORDERS) ?? []
  }
}
