import API from '@/network/API'
import { CustomSuccessData } from 'axios'
import ShopInformation from '@/utils/shop-information-manager/models/ShopInformation'
import Information from '@/utils/shop-information-manager/models/Information'

const _baseUrl = '/restaurants/v1.0'

function _userBaseInformation (shopId: string): string {
  return `${_baseUrl}/${shopId}/userBaseInformation`
}

async function fetchShopBaseInformation (shopId: string): Promise<CustomSuccessData<ShopInformation>> {
  return API.instance().get<ShopInformation>(_userBaseInformation(shopId), undefined, {
    isAuthorization: false,
    isSpin: false
  })
}

function _restaurantInformation (shopId: string): string {
  return `${_baseUrl}/${shopId}/restaurantInfo`
}

async function fetchShopInformation (shopId: string): Promise<CustomSuccessData<Information>> {
  return API.instance().get<Information>(_restaurantInformation(shopId), undefined, {
    isAuthorization: false,
    isSpin: true
  })
}

export { fetchShopBaseInformation, fetchShopInformation }
