enum CouponError {
  CouponNotExisted = 11001,
  UserReceived = 11002,
  ShareLinkExpired = 11003,
  ShareLinkInvalid = 11005,
  CouponAlreadyReceived = 11006,
  CouponNotEnough = 11008,
  CouponConNotReceived = 11009,
  CouponCanNotSendToSelf = 11011
}

enum OrderError {
  DistanceTooFarAway = 10015,
  POSOffline = 10018
}

enum SMSError {
  SendSMSFailed = 30001,
  TimeTooShort = 30004
}

enum FirebaseError {
  FirebaseError = 30006
}

enum PaymentError {
  CardMismatched = 50002,
  PriceChanged = 50003,
  PaymentOnProcess = 50006,
  DuplicatePayment = 50007,
  PriceMismatch = 50017, // deprecated
  CouponInvalid = 50032,
  NoEnoughPoints = 50033,
  GiftCardNotEnough = 50035,
  GiftCardAuthFailed = 50036,
  QRCodeExpired = 50046,
  GiftCardNotExisted = 50024,
  PaymentResponseMalformed = 50049,
  GiftCardNotSupported = 50038,
  GiftCardUnavailable = 50024,
  ScanToPayUnavailable = 50047,
  LoginExpire = 102
}

export { CouponError, OrderError, SMSError, FirebaseError, PaymentError }
