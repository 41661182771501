import UserMenu from './models/user-menu/UserMenu'
import { fetchUserMenu as fetchUserMenuFromServer } from './api'
import { getIsLangEnabled, updateIsLangEnabled } from '@/utils/menu-manager/utils/lang-name'

export default class MenuManager {
  private static _manager: MenuManager

  static instance (): MenuManager {
    if (this._manager === undefined) {
      this._manager = new MenuManager()
    }

    return this._manager
  }

  private _userMenuMap: Map<string, UserMenu>

  private constructor () {
    this._userMenuMap = new Map<string, UserMenu>()
  }

  async fetchUserMenu (shopId: string): Promise<UserMenu> {
    const userMenu = this._userMenuMap.get(shopId)

    if (userMenu === undefined) {
      try {
        const { data } = await fetchUserMenuFromServer(shopId)
        data.gratuitySetting.gratuities.sort((gratuity1, gratuity2) => gratuity2.gratuityNumber - gratuity1.gratuityNumber)
        const isLangEnabled = data.itemLanguages.filter(itemLanguage => itemLanguage.isEnabled).length > 1
        updateIsLangEnabled(isLangEnabled, shopId)
        this._userMenuMap.set(shopId, data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    } else {
      getIsLangEnabled(shopId)
    }

    return Promise.resolve(userMenu)
  }
}
