import QRCodeType from '../models/QRCodeType'
import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import { unclaimedGiftCard } from '@/views/app/utils/path-resolver/api'

export default class GiftCardPathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    const { t: type, rid: giveId } = this._pathQuery || {}

    if (type !== undefined && giveId !== undefined && parseInt(type) === QRCodeType.GiftCardFromFriend) {
      try {
        const { data } = await unclaimedGiftCard(giveId)
        return Promise.resolve({ isResolved: true, pathName: 'giftcardClaim', params: { giftCardInfo: JSON.stringify(data) } })
      } catch (error) {
        return Promise.reject(new Error((error as { code: number }).code === 50051 ? 'giftCardConfirmed' : ((error as { code: number }).code === 50045 ? 'giftCardExpired' : 'requestFail')))
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
