import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28088d38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-vertical-center" }
const _hoisted_2 = { class: "right-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back_icon = _resolveComponent("back-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("  <a-affix>"),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.navBackground),
      style: _normalizeStyle({ background: _ctx.customBackgroundColor })
    }, [
      _createElementVNode("span", {
        class: "back",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toBack && _ctx.toBack(...args)))
      }, [
        (_ctx.isShowBack)
          ? (_openBlock(), _createBlock(_component_back_icon, { key: 0 }))
          : _renderSlot(_ctx.$slots, "left", { key: 1 }, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "middle", {}, undefined, true)
      ]),
      _createElementVNode("p", _hoisted_2, [
        _renderSlot(_ctx.$slots, "right", {}, undefined, true)
      ])
    ], 6 /* CLASS, STYLE */),
    _createCommentVNode("  </a-affix>")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}