export default function buildQueryParams(): { [key: string]: string } {
  const queryStr = window.location.search.substring(1)
  const queries = queryStr.split('&')
  const result: { [key: string]: string } = {}
  queries.forEach(query => {
    const keyValue = query.split('=')
    const key = keyValue[0]
    const value = keyValue[1]
    if (key !== undefined && value !== undefined) {
      result[key] = value
    }
  })
  return result
}
