import SessionOrderModel from './models/SessionOrderModel'
import SessionMenuOrder from './models/SessionMenuOrder'
import SessionDineInModel from './models/SessionDineInModel'
import SessionOnlineModel from './models/SessionOnlineModel'
import SessionTableFastModel from './models/SessionTableFastModel'
import SessionViewMenuOrder from './models/SessionViewMenuOrder'
import SessionWaitlistMenuModel from './models/SessionWaitlistMenuModel'
import SessionShareMenuModel from './models/SessionShareMenuModel'
import SessionNoTableFastModel from './models/SessionNoTableFastModel'
import SessionScanToPayModel from './models/SessionScanToPayModel'

export default class OrderInformationManager {
  private static _orderInformationManager: OrderInformationManager

  static instance(): OrderInformationManager {
    if (this._orderInformationManager === undefined) {
      this._orderInformationManager = new OrderInformationManager()
    }

    return this._orderInformationManager
  }

  private _sessionOrderModel: SessionOrderModel | undefined

  private constructor() {
    this._sessionOrderModel = undefined
  }

  get sessionOrderModel(): SessionOrderModel | undefined {
    return this._sessionOrderModel
  }

  removeSessionOrderModel(): void {
    this._sessionOrderModel = undefined
  }

  setSessionDineInOrder(model: SessionDineInModel): void {
    this._sessionOrderModel = model
  }

  setSessionMenuOrder(model: SessionMenuOrder): void {
    this._sessionOrderModel = model
  }

  setSessionNoTableFastOrder(model: SessionNoTableFastModel): void {
    this._sessionOrderModel = model
  }

  setSessionOnlineOrder(model: SessionOnlineModel): void {
    this._sessionOrderModel = model
  }

  setSessionTableFastOrder(model: SessionTableFastModel): void {
    this._sessionOrderModel = model
  }

  setSessionViewMenuOrder(model: SessionViewMenuOrder): void {
    this._sessionOrderModel = model
  }

  setSessionWaitlistOrder(model: SessionWaitlistMenuModel): void {
    this._sessionOrderModel = model
  }

  setSessionShareMenuOrder(model: SessionShareMenuModel): void {
    this._sessionOrderModel = model
  }

  setSessionScanToPayModel(model: SessionScanToPayModel): void {
    this._sessionOrderModel = model
  }

  setSessionOrderModel(model: SessionOrderModel): void {
    this._sessionOrderModel = model
  }
}
