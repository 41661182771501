const holidays: { [holidayName: string]: number } = {
  "New Year's Day": 1, // 1.1

  'Martin Luther King Jr. Day': 2, // 1月的第三个星期一

  "Washington's Birthday": 3, // 2月的第三个星期一

  'Memorial Day': 4, // 5月的最后一个星期一 19.5.27

  'Independence Day': 5, // 7.4

  'Labor Day': 6, // 9月的第一个星期一 19.9.2

  'Columbus Day': 7, // 10月的第二个星期一 19.10.14

  "Veteran's Day": 8, // 11.11

  'Thanksgiving Day': 9, // 11月的第四个星期四 19.11.28

  'Christmas Day': 10 // 12.25
}

export default holidays
