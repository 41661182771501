import Information from './models/Information'
import { fetchShopInformation as fetchShopInformationFromServer } from './api'

export default class ShopInformationManager {
  private static _manager: ShopInformationManager

  static instance (): ShopInformationManager {
    if (this._manager === undefined) {
      this._manager = new ShopInformationManager()
    }

    return this._manager
  }

  private _informationMap: Map<string, Information>

  private constructor () {
    this._informationMap = new Map<string, Information>()
  }

  async fetchShopInformation (shopId: string, forceFetch = false): Promise<Information> {
    const information = this._informationMap.get(shopId)

    if (information === undefined || forceFetch) {
      try {
        const { data } = await fetchShopInformationFromServer(shopId)
        this._informationMap.set(shopId, data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.resolve(information)
  }
}
