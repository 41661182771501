enum QRCodeType {
  DineInOrder = 1,
  Waitlist,
  OnlineOrder,
  Login,
  FastOrder,
  MenuOrder,
  ViewMenu = 8,
  ShopDetail,
  ScanPay,
  GiftCardFromFriend,
  PCOnlineOrder,
  WaitlistDetail
}

export default QRCodeType
