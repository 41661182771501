import { CustomSuccessData } from 'axios'
import QRCodeResult from '../models/QRCodeResult'
import UnclaimedGiftCardInfo from '@/views/giftcard-claim/models/UnclaimedGiftCardInfo'
import MenuQRCodeResult from '../models/MenuQRCodeResult'
import API from '@/network/API'
import ShareInfo from '@/views/share-get/models/ShareInfo'
import OnlineOrder from '@/utils/pay-utils/models/order-models/OnlineOrder'

const _url = '/restaurants/v1.0'

function _buildQRCodeUrl(code: string): string {
  return `${_url}/qrCode/${code}`
}

async function fetchQrCodeResult(code: string): Promise<CustomSuccessData<QRCodeResult>> {
  return API.instance().get<QRCodeResult>(_buildQRCodeUrl(code))
}

const _firebaseUrl = '/restaurantOrders/v1.0/v1/firebase/restaurants'

function _buildShopFirebaseUrl(shopId: string, tableId: string, autoId: string): string {
  return `${_firebaseUrl}/${shopId}/tableOrdersInfo/${tableId}/${autoId}`
}

async function fetchShopTableInformation(shopId: string, tableId: string, autoId: string): Promise<CustomSuccessData<void>> {
  return API.instance().get<void>(_buildShopFirebaseUrl(shopId, tableId, autoId))
}

// 获取他人赠送待领取Gift Card信息（通过链接）
async function unclaimedGiftCard(giveId: string): Promise<CustomSuccessData<UnclaimedGiftCardInfo>> {
  return API.instance().get<UnclaimedGiftCardInfo>(`restaurantPay/v1.0/public/giftcards/${giveId}`, undefined, { isAuthorization: false })
}

// 用户查看分享
async function userShareInfo(shareCode: string): Promise<CustomSuccessData<ShareInfo>> {
  return API.instance().get<ShareInfo>(`${_url}/public/share`, { shareCode })
}

const _menuUrl = `${_url}/public/menu/qrcode`

function _buildMenuUrl(code: string) {
  return `${_menuUrl}/${code}`
}

async function fetchMenuQRCodeMenuId(code: string): Promise<CustomSuccessData<MenuQRCodeResult>> {
  return API.instance().get<MenuQRCodeResult>(_buildMenuUrl(code), undefined, { isAuthorization: false })
}

async function fetchPcOnlineOrder(key: string): Promise<CustomSuccessData<{ onlineOrder: OnlineOrder, restaurantId: string }>> {
  return API.instance().get<{ onlineOrder: OnlineOrder, restaurantId: string }>('restaurantPay/v1.0/public/scan/pay/orderInfo', { key })
}

const _shopIdUrl = `${_url}/restaurant/domain`

async function fetchShopIdByDomain(domain: string): Promise<CustomSuccessData<{ id: string }>> {
  return API.instance().get<{ id: string }>(_shopIdUrl, { domain }, {
    isAuthorization: false,
    isSpin: true
  })
}

export {
  fetchQrCodeResult,
  fetchShopTableInformation,
  unclaimedGiftCard,
  userShareInfo,
  fetchMenuQRCodeMenuId,
  fetchPcOnlineOrder,
  fetchShopIdByDomain
}
