import LocalStorageManager from '@/utils/local-storage-manager'
import fetchAccessToken from '../api/index'
import AccountLoginInformation from '@/utils/account-manager/models/AccountLoginInformation'
import API from '@/network/API'
import AccountManager from '@/utils/account-manager'

export function updateAccountInformation (loginInformation: AccountLoginInformation, isFromLogin = true): void {
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    expires_in: expiresIn,
    userInfo
  } = loginInformation

  AccountManager.instance().userInformation = userInfo
  API.instance().authorizationToken = accessToken

  const localStorageManager = new LocalStorageManager()
  localStorageManager.accessToken = accessToken
  localStorageManager.refreshToken = refreshToken
  localStorageManager.expires = new Date().valueOf() + expiresIn * 1000

  if (isFromLogin) {
    const { id, name, phone } = userInfo
    localStorageManager.userId = id
    localStorageManager.userNamePhone = { name, phone }
  }
}

export default async function (): Promise<void> {
  const localStorageManager = new LocalStorageManager()
  const expires = localStorageManager.expires

  if (expires === -1) {
    return Promise.resolve()
  } else {
    const currentTimestamp = new Date().valueOf()

    if (expires - currentTimestamp < 86400000) {
      const refreshToken = localStorageManager.refreshToken
      try {
        const { data } = await fetchAccessToken(refreshToken)
        updateAccountInformation(data, false)
        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    } else {
      API.instance().authorizationToken = localStorageManager.accessToken
      return Promise.resolve()
    }
  }
}
