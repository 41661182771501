import moment from 'moment-timezone'
import isHoliday from '../utils/is-holiday'
import { Item, UserItem } from '../models/item'
import TimeManager from '@/network/utils/TimeManager'
import MenuTimeRange from '../models/menu/MenuTimeRange'
import TriedItemsManager from '@/utils/tried-items-manager'
import MenuManager from '@/utils/menu-manager'
import LocalStorageManager from '@/utils/local-storage-manager'
import TriedItem from '@/utils/tried-items-manager/models/TriedItem'
import Holiday from '../models/holiday/Holiday'
import CustomHoliday from '../models/holiday/CustomHoliday'
import { Weekday, WorkdayMenu } from '../models/menu/models/WorkdayMenu'
import Category from '../models/menu/models/Category'
import ValidWeekday from '../models/enum/ValidWeekday'
import CategoryType from '../models/enum/CategoryType'
import CategoryMenuId from '../models/menu/models/CategoryMenuId'
import SpecialCategoryName from '../models/enum/SpecialCategoryName'
import UserMenu from '../models/user-menu/UserMenu'
import OrderMenu from '../models/menu'
import OrderMenuCategory from '../models/menu/OrderMenuCategory'
import MenuInformation from '../models/menu/MenuInformation'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
import { CustomSuccessData } from 'axios'
import { userShareInfo } from '@/views/app/utils/path-resolver/api'
import ShareInfo from '@/views/share-get/models/ShareInfo'
import VisibilityType from '@/utils/menu-manager/models/enum/VisibilityType'

type Moment = moment.Moment

function _isCustomHolidayDay(todayMoment: Moment, customHolidays: CustomHoliday[]): CustomHoliday | undefined {
  const month = todayMoment.month() + 1
  const day = todayMoment.date()
  return customHolidays.find(holiday => holiday.openDay === day && holiday.openMonth === month)
}

function _updateMomentHourMinuteSecond(m: Moment, hour: number, minute: number, second: number, millisecond?: number): void {
  m.hour(hour)
  m.minute(minute)
  m.second(second)

  if (millisecond !== undefined) {
    m.millisecond(millisecond)
  }
}

type BusinessRange = { openTimestamp: number, endTimestamp: number }

function _getBusinessRange(dayMoment: Moment, openHour: number, openMinute: number, closeHour: number, closeMinute: number): BusinessRange {
  if (openHour === closeHour && openMinute === closeMinute && openHour === -1) {
    return {
      openTimestamp: 0,
      endTimestamp: 0
    }
  }

  const compareMoment = moment(dayMoment)
  _updateMomentHourMinuteSecond(compareMoment, openHour, openMinute, 0)
  const openTimestamp = compareMoment.valueOf()
  _updateMomentHourMinuteSecond(compareMoment, closeHour, closeMinute, 0)
  let endTimestamp = compareMoment.valueOf()

  // `开店时间的小时` > `关店时间的小时` (如 晚上10点开店，第二天凌晨2点关店)
  if (endTimestamp <= openTimestamp) {
    endTimestamp += 3600000 * 24
  }

  return {
    openTimestamp,
    endTimestamp
  }
}

function _getHolidayBusinessRange(todayMoment: Moment, holidays: Holiday[], customHolidays: CustomHoliday[]): BusinessRange[] {
  const ranges: BusinessRange[] = []
  const holidayIndex = isHoliday(todayMoment)

  if (holidayIndex !== -1) {
    const holiday = holidays.find(holiday => holiday.day === holidayIndex)

    if (holiday !== undefined) {
      const {
        openTimeHour,
        openTimeMinute,
        closeTimeMinute,
        closeTimeHour
      } = holiday
      ranges.push(_getBusinessRange(todayMoment, openTimeHour, openTimeMinute, closeTimeHour, closeTimeMinute))
    }
  }

  const customHoliday = _isCustomHolidayDay(todayMoment, customHolidays)

  if (customHoliday !== undefined) {
    const {
      openTimeHour,
      openTimeMinute,
      closeTimeMinute,
      closeTimeHour
    } = customHoliday
    ranges.push(_getBusinessRange(todayMoment, openTimeHour, openTimeMinute, closeTimeHour, closeTimeMinute))
  }

  return ranges
}

function currentWorkdayMenuId(timestamp: number, timezone: string, workdayMenus: WorkdayMenu[], holidays: Holiday[], customHolidays: CustomHoliday[]): string | undefined {
  const currentTimestamp = timestamp - 1
  const todayMoment = moment.tz(currentTimestamp, timezone)

  const holidayRanges = _getHolidayBusinessRange(todayMoment, holidays, customHolidays)
  const validHolidayRanges = holidayRanges.filter(({
    openTimestamp,
    endTimestamp
  }) => {
    return currentTimestamp >= openTimestamp && currentTimestamp < endTimestamp
  })
  if (holidayRanges.length === 0 || validHolidayRanges.length === holidayRanges.length) {
    const menus = workdayMenus.slice()
    const saturdayMenu = menus.find(menu => menu.weekday === Weekday.Saturday)
    if (saturdayMenu) {
      const lastSaturdayMenu = {
        weekday: 0,
        businessMenus: saturdayMenu.businessMenus
      }
      lastSaturdayMenu.weekday = 0
      menus.push(lastSaturdayMenu)
    }

    for (const workdayMenu of menus) {
      const weekDay = moment(todayMoment).day(workdayMenu.weekday - 1)

      for (const {
        openTimeHour,
        openTimeMinute,
        closeTimeHour,
        closeTimeMinute,
        menuId
      } of workdayMenu.businessMenus) {
        const {
          openTimestamp,
          endTimestamp
        } = _getBusinessRange(weekDay, openTimeHour, openTimeMinute, closeTimeHour, closeTimeMinute)
        if (currentTimestamp >= openTimestamp && currentTimestamp < endTimestamp) {
          return menuId
        }
      }
    }
  }

  return undefined
}

async function currentMenuId(shopId: string, isOnline: boolean): Promise<string | undefined> {
  const timestamp = TimeManager.instance().currentMSTimestamp

  try {
    const result = await Promise.all([ShopInformationManager.instance().fetchShopInformation(shopId), MenuManager.instance().fetchUserMenu(shopId)])
    const shopInformation = result[0]
    const userMenuInformation = result[1]
    const timezone = shopInformation.information.timezone
    const { workdayMenus, onlineWorkdayMenu, holidays, customHolidays } = userMenuInformation
    const menus = (isOnline && !onlineWorkdayMenu.isSameAsMenu) ? onlineWorkdayMenu.menus : workdayMenus
    return Promise.resolve(currentWorkdayMenuId(timestamp, timezone, menus, holidays, customHolidays))
  } catch (e) {
    return Promise.reject(e)
  }
}

function _currentCategories(menuId: string, categories: Category[]): Category[] {
  const menuCategories = categories.filter(category => category.menus.find(categoryMenuId => categoryMenuId.menuId === menuId) !== undefined)
  return menuCategories.sort((category1, category2) => {
    const categoryMenuId1 = category1.menus.find(categoryMenuId => categoryMenuId.menuId === menuId)
    const categoryMenuId2 = category2.menus.find(categoryMenuId => categoryMenuId.menuId === menuId)

    if (categoryMenuId1 !== undefined && categoryMenuId2 !== undefined) {
      return categoryMenuId1.index - categoryMenuId2.index
    }

    return 0
  })
}

function _sortRecommendAndOrderedItems(items: Item[], menuId: string, categories: Category[]): Item[] {
  return items.sort((item1, item2) => {
    const categoryId1 = item1.categories[0]
    const categoryId2 = item2.categories[0]

    if (categoryId1.categoryId === categoryId2.categoryId) {
      return categoryId1.index - categoryId2.index
    }

    const category1 = categories.find(category => category.categoryId === categoryId1.categoryId)
    const category2 = categories.find(category => category.categoryId === categoryId2.categoryId)

    if (category1 && category2 && category1.menus && category2.menus) {
      const categoryMenuId1 = category1.menus.find(categoryMenuId => categoryMenuId.menuId === menuId)
      const categoryMenuId2 = category2.menus.find(categoryMenuId => categoryMenuId.menuId === menuId)

      if (categoryMenuId1 && categoryMenuId2 && categoryMenuId1.index && categoryMenuId2.index) {
        return categoryMenuId1.index - categoryMenuId2.index
      }
    }

    return 0
  })
}

function _sortItems(items: Item[], menuId: string, categoryId: string): Item[] {
  return items.sort((item1, item2) => {
    const categoryId1 = item1.categories.find(cId => cId.categoryId === categoryId && cId.menuId === menuId)
    const categoryId2 = item2.categories.find(cId => cId.categoryId === categoryId && cId.menuId === menuId)

    if (categoryId1 && categoryId2) {
      return categoryId1.index - categoryId2.index
    }

    return 0
  })
}

function _isItemValid(item: Item, isOnlineOrder: boolean, isDineIn: boolean): boolean {
  if (!item.itemAdditionalInformation.visibleToGuest) {
    return false
  }

  const visibilityType = item.itemAdditionalInformation.visibilityType

  if (visibilityType === VisibilityType.allTypes) {
    return true
  }

  if (isOnlineOrder) {
    if (visibilityType === VisibilityType.onlineOnly) {
      return true
    }
    return false
  }

  if (visibilityType === VisibilityType.inStoreOnly) {
    return true
  }

  if (isDineIn) {
    if (visibilityType === VisibilityType.dineInOnly) {
      return true
    }
    return false
  }

  if (!isDineIn) {
    if (visibilityType === VisibilityType.toGoOnly) {
      return true
    }
    return false
  }

  return false
}

// function _currentItems(menuId: string, categoryId: string, items: Item[], isOnlineOrder: boolean, isDineIn: boolean): Item[] {
//   const categoryItems = items.filter(item => item.itemAdditionalInformation.visibleToGuest &&
//     ((isOnlineOrder && item.itemAdditionalInformation.visibilityType !== VisibilityType.inStoreOnly) ||
//       (!isOnlineOrder && item.itemAdditionalInformation.visibilityType !== VisibilityType.onlineOnly)) &&
//     item.categories.find(cId => cId.categoryId === categoryId && cId.menuId === menuId) !== undefined)

//   return _sortItems(categoryItems, menuId, categoryId)
// }

function _currentItems(menuId: string, categoryId: string, items: Item[], isOnlineOrder: boolean, isDineIn: boolean): Item[] {
  const categoryItems = items.filter(item => _isItemValid(item, isOnlineOrder, isDineIn) && item.categories.find(cId => cId.categoryId === categoryId && cId.menuId === menuId) !== undefined)
  return _sortItems(categoryItems, menuId, categoryId)
}

// function _recommendItems(menuId: string, items: Item[], categories: Category[], isOnlineOrder: boolean, isDineIn: boolean): Item[] {
//   const rItems: Item[] = []
//   const itemIds: string[] = []
//   items.forEach(item => {
//     if (item.itemAdditionalInformation.visibleToGuest &&
//       ((isOnlineOrder && item.itemAdditionalInformation.visibilityType !== VisibilityType.inStoreOnly) ||
//         (!isOnlineOrder && item.itemAdditionalInformation.visibilityType !== VisibilityType.onlineOnly)) &&
//       item.itemAdditionalInformation.isRecommend &&
//       item.categories.find(categoryId => categoryId.menuId === menuId) &&
//       !itemIds.includes(item.itemId)) {
//       rItems.push(item)
//       itemIds.push(item.itemId)
//     }
//   })

//   return _sortRecommendAndOrderedItems(rItems, menuId, categories)
// }

function _recommendItems(menuId: string, items: Item[], categories: Category[], isOnlineOrder: boolean, isDineIn: boolean): Item[] {
  const rItems: Item[] = []
  const itemIds: string[] = []
  items.forEach(item => {
    if (_isItemValid(item, isOnlineOrder, isDineIn) &&
      item.itemAdditionalInformation.isRecommend &&
      item.categories.find(categoryId => categoryId.menuId === menuId) &&
      !itemIds.includes(item.itemId)) {
      rItems.push(item)
      itemIds.push(item.itemId)
    }
  })

  return _sortRecommendAndOrderedItems(rItems, menuId, categories)
}

// function _userItems(menuId: string, items: Item[], categories: Category[], orderedItemIds: UserItem[], isOnlineOrder: boolean, isDineIn: boolean): Item[] {
//   if (orderedItemIds.length === 0) {
//     return []
//   }

//   const itemIds = orderedItemIds.map(itemId => itemId.itemId)
//   const uItems = items.reduce<Item[]>((result, item) => {
//     item.isAvailable = item.categories.find(categoryId => categoryId.menuId === menuId) !== undefined

//     if (item.isAvailable &&
//       item.itemAdditionalInformation.visibleToGuest &&
//       ((isOnlineOrder && item.itemAdditionalInformation.visibilityType !== VisibilityType.inStoreOnly) ||
//         (!isOnlineOrder && item.itemAdditionalInformation.visibilityType !== VisibilityType.onlineOnly)) &&
//       itemIds.includes(item.itemId)) {
//       item.isOrderedBefore = true
//       result.push(item)
//     } else {
//       item.isOrderedBefore = false
//     }

//     return result
//   }, [])

//   _sortRecommendAndOrderedItems(uItems, menuId, categories)
//   return uItems
// }

function _userItems(menuId: string, items: Item[], categories: Category[], orderedItemIds: UserItem[], isOnlineOrder: boolean, isDineIn: boolean): Item[] {
  if (orderedItemIds.length === 0) {
    return []
  }

  const itemIds = orderedItemIds.map(itemId => itemId.itemId)
  const uItems = items.reduce<Item[]>((result, item) => {
    item.isAvailable = item.categories.find(categoryId => categoryId.menuId === menuId) !== undefined

    if (item.isAvailable && _isItemValid(item, isOnlineOrder, isDineIn) && itemIds.includes(item.itemId)) {
      item.isOrderedBefore = true
      result.push(item)
    } else {
      item.isOrderedBefore = false
    }
    return result
  }, [])

  _sortRecommendAndOrderedItems(uItems, menuId, categories)
  return uItems
}

function menuWithMenuId(menuId: string, userMenu: UserMenu, isOnlineOrder: boolean, isDineIn: boolean): OrderMenu | undefined {
  if (menuId !== '') {
    const orderMenu = new OrderMenu()
    orderMenu.itemLanguages = userMenu.itemLanguages
    orderMenu.items = userMenu.items
    orderMenu.options = userMenu.options
    orderMenu.modifiers = userMenu.modifiers
    const menu = userMenu.menus.find(menu => menu.menuId === menuId)
    orderMenu.name = menu?.menuName ?? ''
    orderMenu.menuId = menuId
    orderMenu.categories = []
    const categories = _currentCategories(menuId, userMenu.categories)

    categories.forEach(category => {
      const orderMenuCategory = new OrderMenuCategory()
      orderMenuCategory.translations = category.translations
      const items = _currentItems(menuId, category.categoryId, userMenu.items, isOnlineOrder, isDineIn)

      if (items.length > 0) {
        orderMenuCategory.items = items
        orderMenuCategory.categoryId = category.categoryId
        orderMenuCategory.name = category.name
        orderMenu.categories.push(orderMenuCategory)
      }
    })

    return orderMenu
  }

  return undefined
}

function _isWeekdayValid(currentWeekday: Weekday, weekday: number): ValidWeekday {
  return currentWeekday === weekday
    ? ValidWeekday.SameDay
    : (currentWeekday === Weekday.Sunday ? 7 : currentWeekday - 1) === weekday
      ? ValidWeekday.PreviousDay
      : ValidWeekday.Invalid
}

function _buildTimeMomentTimestamp(currentMoment: Moment, hour: number, minute: number, validWeekDay: ValidWeekday): number {
  const timeMoment = moment(currentMoment)
  _updateMomentHourMinuteSecond(timeMoment, hour, minute, 0, 0)
  let timestamp = timeMoment.valueOf()

  if (validWeekDay === ValidWeekday.PreviousDay) {
    timestamp -= 86400000
  }

  return timestamp
}

function _isCurrentTimestampInsideBusinessRange(
  weekday: Weekday,
  openTimeHour: number,
  openTimeMinute: number,
  closeTimeHour: number,
  closeTimeMinute: number,
  timezone: string): boolean {
  const currentMoment = moment.tz(TimeManager.instance().currentMSTimestamp, timezone)
  const currentWeekday = currentMoment.day() + 1
  const isWeekdayValid = _isWeekdayValid(currentWeekday, weekday)

  if (isWeekdayValid !== ValidWeekday.Invalid) {
    const openTimestamp = _buildTimeMomentTimestamp(currentMoment, openTimeHour, openTimeMinute, isWeekdayValid)
    let closeTimestamp = _buildTimeMomentTimestamp(currentMoment, closeTimeHour, closeTimeMinute, isWeekdayValid)
    const currentTimestamp = currentMoment.valueOf()

    // 跨天判断，不加跨天会有bug
    if (closeTimestamp <= openTimestamp) {
      closeTimestamp += 86400000
    }

    return currentTimestamp >= openTimestamp && currentTimestamp <= closeTimestamp
  }

  return false
}

function shopBusinessHours(workdayMenus: WorkdayMenu[], timezone: string): MenuInformation[] {
  const formatTimeNumber = (num: number) => num < 10 ? `0${num}` : `${num}`
  const currentWorkdayMenus = workdayMenus.sort((workdayMenu1, workdayMenu2) => workdayMenu1.weekday - workdayMenu2.weekday)

  if (currentWorkdayMenus.length > 0 && currentWorkdayMenus[0].weekday === 1) {
    const workdayMenu = currentWorkdayMenus.shift()

    if (workdayMenu !== undefined) {
      currentWorkdayMenus.push(workdayMenu) // 将星期天放到时间最后
    }
  }

  return currentWorkdayMenus.reduce<MenuInformation[]>((menuInformationList, workdayMenu) => {
    const {
      weekday,
      businessMenus
    } = workdayMenu

    if (businessMenus.length > 0) {
      const currentBusinessMenus = businessMenus.sort((businessMenu1, businessMenu2) => {
        const timestamp1 = moment().hour(businessMenu1.openTimeHour).minute(businessMenu1.openTimeMinute).second(0).valueOf()
        const timestamp2 = moment().hour(businessMenu2.openTimeHour).minute(businessMenu2.openTimeMinute).second(0).valueOf()
        return timestamp1 - timestamp2
      })

      const businessHours = currentBusinessMenus.reduce<MenuTimeRange[]>((businessMenus, businessMenu) => {
        const menuTimeRange = {
          timeRange: '',
          isValid: false
        }
        menuTimeRange.timeRange = `${formatTimeNumber(businessMenu.openTimeHour)}:${formatTimeNumber(businessMenu.openTimeMinute)} - ${formatTimeNumber(businessMenu.closeTimeHour)}:${formatTimeNumber(businessMenu.closeTimeMinute)}`
        menuTimeRange.isValid = _isCurrentTimestampInsideBusinessRange(weekday, businessMenu.openTimeHour, businessMenu.openTimeMinute,
          businessMenu.closeTimeHour, businessMenu.closeTimeMinute, timezone)
        businessMenus.push(menuTimeRange)
        return businessMenus
      }, [])

      menuInformationList.push({
        weekday,
        businessHours
      })
    }

    return menuInformationList
  }, [])
}

function menuBusinessHours(workdayMenus: WorkdayMenu[], menuId: string, timezone: string): MenuInformation[] {
  if (menuId === '') {
    return shopBusinessHours(workdayMenus, timezone)
  }

  const menuWorkdayMenus = workdayMenus.reduce<WorkdayMenu[]>((workdayMenus, workdayMenu) => {
    const {
      weekday,
      businessMenus
    } = workdayMenu
    const menuBusinessMenus = businessMenus.filter(businessMenu => businessMenu.menuId === menuId)

    if (menuBusinessMenus.length > 0) {
      workdayMenus.push({
        weekday,
        businessMenus: menuBusinessMenus
      })
    }

    return workdayMenus
  }, [])

  return shopBusinessHours(menuWorkdayMenus, timezone)
}

function _allMenuCategories(categories: Category[]): Category[] {
  if (categories.length > 1) {
    const returnCategories: Category[] = []
    returnCategories.push(...categories)

    const categoryMaxIndex = (categoryMenuIds: CategoryMenuId[]): number => {
      const indexes = categoryMenuIds.map(categoryMenuId => categoryMenuId.index)
      return Math.max.apply(null, indexes)
    }

    return returnCategories.sort((category1, category2) => categoryMaxIndex(category1.menus) - categoryMaxIndex(category2.menus))
  }

  return categories
}

function _allMenuCategoryItems(items: Item[], categoryId: string): Item[] {
  const categoryItems = items.filter(item => item.itemAdditionalInformation.visibleToGuest && item.categories.some(itemCategoryId => itemCategoryId.categoryId === categoryId))
  const itemCategoryMaxIndex = (item: Item): number => {
    const categoryIndexes = item.categories.filter(itemCategory => itemCategory.categoryId === categoryId).map(category => category.index)
    return Math.max.apply(null, categoryIndexes)
  }

  if (categoryItems.length > 1) {
    return categoryItems.sort((categoryItem1, categoryItem2) => itemCategoryMaxIndex(categoryItem1) - itemCategoryMaxIndex(categoryItem2))
  }

  return categoryItems
}

function _friendRecommendItems(items: Item[], shareItemIds: string[]): Item[] {
  return items.filter(item => shareItemIds.some(shareItemId => shareItemId === item.itemId))
}

function _currentAllMenu(menuId: string, orderedItemIds: UserItem[], userMenu: UserMenu, isOnlineOrder: boolean, isDineIn: boolean): OrderMenu | undefined {
  const orderMenu = menuWithMenuId(menuId, userMenu, isOnlineOrder, isDineIn)

  if (orderMenu !== undefined) {
    const userItems = _userItems(orderMenu.menuId, userMenu.items, userMenu.categories, orderedItemIds, isOnlineOrder, isDineIn)

    if (userItems.length > 0) {
      const userOrderMenuCategory: OrderMenuCategory = {
        items: userItems,
        categoryId: CategoryType.Tried,
        name: SpecialCategoryName.Tried,
        translations: {}
      }
      orderMenu.categories.unshift(userOrderMenuCategory)
    }

    const hotItems = _recommendItems(orderMenu.menuId, userMenu.items, userMenu.categories, isOnlineOrder, isDineIn)

    if (hotItems.length > 0) {
      const hotOrderMenuCategory: OrderMenuCategory = {
        items: hotItems,
        categoryId: CategoryType.Hottest,
        name: SpecialCategoryName.Hottest,
        translations: {}
      }
      orderMenu.categories.unshift(hotOrderMenuCategory)
    }
  }

  return orderMenu
}

async function currentAllMenuFromData(timezone: string, shopId: string, isOnlineOrder: boolean, isDineIn: boolean): Promise<OrderMenu | undefined> {
  try {
    const timestamp = TimeManager.instance().currentMSTimestamp
    const orderMenu = await currentAllMenuFromDataAndTimestamp(timestamp, timezone, shopId, isOnlineOrder, isDineIn)
    return Promise.resolve(orderMenu)
  } catch (e) {
    return Promise.reject(e)
  }
}

async function currentAllMenuFromDataAndTimestamp(timestamp: number, timezone: string, shopId: string, isOnlineOrder: boolean, isDineIn: boolean): Promise<OrderMenu | undefined> {
  try {
    const promises: Promise<UserMenu | TriedItem[]>[] = [MenuManager.instance().fetchUserMenu(shopId)]
    if (new LocalStorageManager().userId !== '') {
      promises.push(TriedItemsManager.instance().fetchUserItems(shopId))
    }
    const result = await Promise.all(promises)
    const userMenu = result[0] as UserMenu
    const orderedItemIds = []
    if (promises.length > 1) {
      orderedItemIds.push(...result[1] as TriedItem[])
    }
    const {
      workdayMenus,
      onlineWorkdayMenu,
      holidays,
      customHolidays
    } = userMenu

    const menus = (isOnlineOrder && !onlineWorkdayMenu.isSameAsMenu) ? onlineWorkdayMenu.menus : workdayMenus
    const menuId = currentWorkdayMenuId(timestamp, timezone, menus, holidays, customHolidays)

    if (menuId !== undefined) {
      return Promise.resolve(_currentAllMenu(menuId, orderedItemIds, userMenu, isOnlineOrder, isDineIn))
    }
  } catch (e) {
    return Promise.reject(e)
  }

  return Promise.resolve(undefined)
}

async function categoryMenu(shopId: string, code = ''): Promise<OrderMenu | undefined> {
  try {
    const result = code.length > 0
      ? await Promise.all([MenuManager.instance().fetchUserMenu(shopId), userShareInfo(code)])
      : await Promise.all([MenuManager.instance().fetchUserMenu(shopId)])
    const userMenu = result[0]
    const shareItemIds = result.length === 2 ? (result[1] as CustomSuccessData<ShareInfo>).data.shareItems.map(shareItem => shareItem.itemId) : []
    const categories = _allMenuCategories(userMenu.categories)
    const orderMenu: OrderMenu = {
      name: '',
      menuId: '',
      categories: [],
      modifiers: userMenu.modifiers,
      options: userMenu.options,
      items: userMenu.items,
      itemLanguages: userMenu.itemLanguages
    }

    if (categories.length > 0) {
      const shareItems = _friendRecommendItems(userMenu.items, shareItemIds)

      if (shareItems.length > 0) {
        const friendCategory: OrderMenuCategory = {
          items: shareItems,
          categoryId: CategoryType.FriendRecommend,
          name: SpecialCategoryName.FriendRecommend,
          translations: {}
        }
        orderMenu.categories.push(friendCategory)
      }

      const items = userMenu.items
      categories.forEach(category => {
        const categoryId = category.categoryId
        const categoryItems = _allMenuCategoryItems(items, categoryId)

        if (categoryItems.length > 0) {
          const orderMenuCategory: OrderMenuCategory = {
            items: categoryItems,
            categoryId: categoryId,
            name: category.name,
            translations: category.translations
          }
          orderMenu.categories.push(orderMenuCategory)
        }
      })
      return Promise.resolve(orderMenu)
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

export {
  shopBusinessHours,
  menuBusinessHours,
  currentAllMenuFromData,
  currentAllMenuFromDataAndTimestamp,
  menuWithMenuId,
  currentMenuId,
  currentWorkdayMenuId,
  categoryMenu
}
