import TriedItem from './models/TriedItem'
import { fetchUserItems as fetchUserItemFromServer } from './api'

export default class TriedItemsManager {
  private static _manager: TriedItemsManager

  static instance (): TriedItemsManager {
    if (this._manager === undefined) {
      this._manager = new TriedItemsManager()
    }

    return this._manager
  }

  private _itemsMap: Map<string, TriedItem[]>

  private constructor () {
    this._itemsMap = new Map<string, TriedItem[]>()
  }

  async fetchUserItems (shopId: string): Promise<TriedItem[]> {
    const items = this._itemsMap.get(shopId)

    if (items === undefined) {
      try {
        const { data } = await fetchUserItemFromServer(shopId)
        this._itemsMap.set(shopId, data)
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.resolve(items)
  }

  reset(): void {
    this._itemsMap = new Map<string, TriedItem[]>()
  }
}
