import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import PayCompleteModel from './PayCompleteModel'

export default class PayCompleteManager {
  private static _manager: PayCompleteManager

  static instance(): PayCompleteManager {
    if (this._manager === undefined) {
      this._manager = new PayCompleteManager()
    }

    return this._manager
  }

  private _payComplete: PayCompleteModel = {
    shopId: '',
    autoId: '',
    orderType: OrderMenuType.Invalid
  }

  setPayComplete(shopId: string, autoId: string, orderType: OrderMenuType): void {
    this._payComplete.shopId = shopId
    this._payComplete.autoId = autoId
    this._payComplete.orderType = orderType
  }

  get payComplete(): PayCompleteModel {
    return this._payComplete
  }

  reset(): void {
    this.setPayComplete('', '', OrderMenuType.Invalid)
  }
}
