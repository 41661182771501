import BusinessMenu from './BusinessMenu'

enum Weekday {
  Sunday = 1,
  Monday,
  TuesDay,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

interface WorkdayMenu {
  weekday: Weekday
  businessMenus: BusinessMenu[]
}

export { WorkdayMenu, Weekday }
