export default class RestaurantInfoManager {
  private static _manager: RestaurantInfoManager

  static instance(): RestaurantInfoManager {
    if (this._manager === undefined) {
      this._manager = new RestaurantInfoManager()
    }

    return this._manager
  }

  private _shopId = ''

  private _fromOpenOrder = '0'

  setParams(shopId: string, fromOpenOrder = '0'): void {
    this._shopId = shopId
    this._fromOpenOrder = fromOpenOrder
  }

  get shopId(): string {
    return this._shopId
  }

  get fromOpenOrder(): string {
    return this._fromOpenOrder
  }
}
