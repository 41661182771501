import OrderedItemModifierOption from '../models/OrderedItemModifierOption'
import OrderedItemModifier from '../models/OrderedItemModifier'
import OrderedItemComboItem from '../models/OrderedItemComboItem'
import OrderedItemType from '../models/OrderedItemType'

function _buildComboOptionStr (comboOptions?: OrderedItemModifierOption[]): string {
  if (comboOptions === undefined || comboOptions.length === 0) {
    return ''
  }

  comboOptions.sort((option1, option2) => option1.optionId.localeCompare(option2.optionId))
  return comboOptions.reduce((optionStr, option) => `${optionStr}${option.optionId}${option.quantity}`, 'O')
}

function _buildComboModifierStr (comboModifiers?: OrderedItemModifier[]): string {
  if (comboModifiers === undefined || comboModifiers.length === 0) {
    return ''
  }

  comboModifiers.sort((modifier1, modifier2) => modifier1.modifierId.localeCompare(modifier2.modifierId))
  return comboModifiers.reduce((modifierStr, modifier) => `${modifierStr}${_buildComboOptionStr([...modifier.options])}`, 'M')
}

function _buildCombosStr (combos?: OrderedItemComboItem[]): string {
  if (combos === undefined || combos.length === 0) {
    return ''
  }

  combos.sort((combo1, combo2) => combo1.itemId.localeCompare(combo2.itemId))
  const buildComboModifiers = (modifiers: OrderedItemModifier[] | undefined) => modifiers === undefined ? undefined : [...modifiers]

  return combos.reduce((comboStr, combo) => `${comboStr}${_buildComboModifierStr(buildComboModifiers(combo.modifiers))}`, '')
}

function _buildOptionStr (options?: OrderedItemModifierOption[]): string {
  if (options === undefined || options.length === 0) {
    return ''
  }

  options.sort((option1, option2) => option1.optionId.localeCompare(option2.optionId))
  return options.reduce<string>((optionStr, option) => `${optionStr}${option.optionId}${option.quantity}`, 'O')
}

function _buildModifierStr (modifiers?: OrderedItemModifier[]): string {
  if (modifiers === undefined || modifiers.length === 0) {
    return ''
  }

  modifiers.sort((modifier1, modifier2) => modifier1.modifierId.localeCompare(modifier2.modifierId))
  return modifiers.reduce<string>((modifierStr, modifier) => `${modifierStr}${_buildOptionStr([...modifier.options])}`, '')
}

function buildItemIdentifier (item: OrderedItemType): string {
  return item.itemId
}

function buildModifierIdentifier (item: OrderedItemType): string {
  const modifiers = item.modifiers === undefined ? undefined : [...item.modifiers]
  const comboItems = item.items === undefined ? undefined : [...item.items]
  return `${item.itemId}${_buildModifierStr(modifiers)}C${_buildCombosStr(comboItems)}N${item.notes ?? ''}`
}

export { buildItemIdentifier, buildModifierIdentifier }
