import { defineComponent, computed, PropType, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import GiftcardCell from '@/components/giftcard-cell/index.vue'
import CommonButton from '@/components/common-button/index.vue'
import UnclaimedGiftCardInfo from '@/views/giftcard-claim/models/UnclaimedGiftCardInfo'
import MyGiftCardInfo from '@/views/giftcard-list/models/MyGiftCardInfo'

export default defineComponent({
  name: 'GiftcardAccept',

  components: {
    GiftcardCell,
    CommonButton
  },

  props: {
    item: {
      type: Object as PropType<UnclaimedGiftCardInfo>,
      required: true
    }
  },

  emits: ['refuse', 'accept'],

  setup(props, { emit }) {
    const { t } = useI18n()

    const fromRestaurant = computed<boolean>(() => props.item.sourceType === 1)

    const giftCardInfo = computed((): MyGiftCardInfo => {
      return {
        giftCardInfo: {
          amount: props.item.amount,
          giftCardId: props.item.giveId,
          price: 0,
          restaurantId: props.item.restaurantBaseInfo?.restaurantId ?? '',
          openTime: '',
          giftCardProportion: 0
        },
        giftCardType: { background: props.item.background, cardType: props.item.cardType },
        shopInfo: props.item.restaurantBaseInfo
      }
    })

    function acceptOrRefuse(accept: boolean) {
      emit(accept ? 'accept' : 'refuse')
    }

    return {
      t,
      ...toRefs(props),
      fromRestaurant,
      giftCardInfo,
      acceptOrRefuse
    }
  }
})
