import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import LocalStorageManager from '@/utils/local-storage-manager'
import ShopAutoId from '@/utils/firebase-storage-manager/models/ShopAutoId'
import DineInOrderCheckModel from '@/views/app/utils/path-resolver/models/DineInOrderCheckModel'
import OrderInformationManager from '@/utils/order-information-manager'
import DineInOrToGo from '@/utils/pay-utils/models/order-models/DineInOrToGo'
import { isDineInOrderProOrder } from '@/utils/dine-in-order-pre-order'
import OrderSubmission from '@/views/order-information/models/OrderSubmission'
import DineInOrderNodeBuilder from '@/firebase/firebase-node-builder/DineInOrderNodeBuilder'
import UserNodeBuilder from '@/firebase/firebase-node-builder/UserNodeBuilder'
import { scanPayOrder } from '@/views/check-scan-to-pay-order/api'
import once from '@/utils/helper/once'
import { buildSessionDineOrder } from '@/utils/order-information-manager/utils/build-session-order-model'

export default class ScanPayResolver extends Resolver {
  private static async _isDineInOrderExisted(shopId: string): Promise<{ shopId: string, autoId: string } | undefined> {
    const localStorageManager = new LocalStorageManager()
    const userId = localStorageManager.userId

    if (userId !== '') {
      const userDineInOrdersNode = new UserNodeBuilder(userId).dineInOrders()
      try {
        const dineInOrdersRaw = await once(userDineInOrdersNode)
        const dineInOrders = dineInOrdersRaw.val()

        if (dineInOrders !== null) {
          const firebaseDineInOrders = dineInOrders as { [autoId: string]: { restaurantId: string, isOrderOwner: boolean } }
          for (const autoId in firebaseDineInOrders) {
            const { restaurantId } = firebaseDineInOrders[autoId]

            if (restaurantId === shopId) {
              return Promise.resolve({
                shopId,
                autoId
              })
            }
          }
        }

        return Promise.resolve(undefined)
      } catch (e) {
        return Promise.reject(e)
      }
    } else {
      const dineInOrders: ShopAutoId[] = localStorageManager.userDineInOrders

      for (const dineInOrder of dineInOrders) {
        if (dineInOrder.restaurantId === shopId) {
          return Promise.resolve({
            shopId,
            autoId: dineInOrder.autoId
          })
        }
      }
      return Promise.resolve(undefined)
    }
  }

  private async _fetchOrderInformation(shopAutoId: ShopAutoId): Promise<DineInOrderCheckModel | undefined> {
    try {
      const {
        restaurantId: shopId,
        autoId
      } = shopAutoId
      const dineInOrderNodeBuilder = new DineInOrderNodeBuilder(shopId, autoId)
      const tableInfoNode = dineInOrderNodeBuilder.tableInfo()
      const userCountNode = dineInOrderNodeBuilder.userCount()
      const dineInOrToGoNode = dineInOrderNodeBuilder.dineInOrToGo()
      const submissionsNode = dineInOrderNodeBuilder.submissions()
      const result = await Promise.all([
        once(tableInfoNode),
        once(userCountNode),
        once(dineInOrToGoNode),
        once(submissionsNode)
      ])
      const firebaseTableInfo = result[0].val()
      const firebaseUserCount = result[1].val()
      const firebaseDineInOrToGo = result[2].val()
      const firebaseSubmissions = result[3].val()

      if (firebaseTableInfo !== null && firebaseUserCount !== null) {
        const tableInfo = firebaseTableInfo as { tableGratuities: number, tableId: string, tableName: string }
        const userCount = firebaseUserCount as number
        const isDineIn = (firebaseDineInOrToGo as number) === DineInOrToGo.dineIn
        const submissions = firebaseSubmissions === null ? [] : firebaseSubmissions as OrderSubmission[]
        const preOrder = isDineInOrderProOrder(submissions)
        return Promise.resolve({
          tableInfo,
          userCount,
          autoId,
          isDineIn,
          preOrder,
          isPaid: false
        })
      } else {
        return Promise.resolve(undefined)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  private static async _fetchOrderIsPaid(shopId: string, autoId: string): Promise<boolean> {
    const orderPaymentsNode = new DineInOrderNodeBuilder(shopId, autoId).orderPayments()
    try {
      const orderPayment = await once(orderPaymentsNode)
      if (orderPayment.val() !== null) {
        const orderPaymentValue = orderPayment.val()
        if (orderPaymentValue !== null) {
          const guestPayments = orderPaymentValue[0].guestPayments
          if (guestPayments[0] !== undefined) {
            const isPaid = guestPayments[0].isPaid
            if (isPaid !== undefined && isPaid) {
              return Promise.resolve(true)
            }
          }
        }
      }
      return Promise.resolve(false)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  async resolvePath(): Promise<ResolveResult> {
    const {
      t: type,
      rid: shopId,
      orderType,
      autoId
    } = this._pathQuery || {}

    if (type !== undefined && shopId !== undefined && orderType !== undefined && autoId !== undefined && parseInt(type) === QRCodeType.ScanPay) {
      const orderTypeNumber = parseInt(orderType)

      try {
        // 1为DineInOrder
        if (orderTypeNumber === 1) {
          // 需先判断是否之前已经有手机单子（扫码开台或者后面加入订单）
          // 有订单：未支付，进入：orderReviewInformation；已支付，进入：已结账订单详情页面
          // 无订单：先调用接口：scanPayOrder(shopId, autoId, orderType, timestamp)，再进入：orderReviewInformation
          const shopAutoId = await ScanPayResolver._isDineInOrderExisted(shopId)

          if (shopAutoId !== undefined) {
            const dineInOrderCheckModel = await this._fetchOrderInformation({
              restaurantId: shopAutoId.shopId,
              autoId: shopAutoId.autoId
            })

            if (dineInOrderCheckModel !== undefined) {
              const isPaid = await ScanPayResolver._fetchOrderIsPaid(shopAutoId.shopId, shopAutoId.autoId)
              const tableInfo = dineInOrderCheckModel.tableInfo
              const sessionDineInModel = buildSessionDineOrder(shopId, shopAutoId.autoId, tableInfo.tableId,
                tableInfo.tableName, dineInOrderCheckModel.userCount, dineInOrderCheckModel.preOrder,
                dineInOrderCheckModel.isDineIn, true, false, tableInfo.tableGratuities, isPaid)

              OrderInformationManager.instance().setSessionDineInOrder(sessionDineInModel)
              if (isPaid) {
                return Promise.resolve({
                  isResolved: true,
                  pathName: 'payComplete'
                })
              } else {
                return Promise.resolve({
                  isResolved: true,
                  pathName: 'orderReviewInformation'
                })
              }
            }
          } else {
            await scanPayOrder(shopId, autoId, orderType)
            return Promise.resolve({
              isResolved: true,
              pathName: 'checkOrderInfo',
              params: {
                shopId,
                autoId,
                tableOrderType: orderTypeNumber,
                orderType
              }
            })
          }
        } else {
          await scanPayOrder(shopId, autoId, orderType)
          return Promise.resolve({
            isResolved: true,
            pathName: 'checkOrderInfo',
            params: {
              shopId,
              autoId,
              tableOrderType: orderTypeNumber,
              orderType
            }
          })
        }
      } catch (error) {
        let errMsg = ''
        switch ((error as { code: number }).code) {
          case 30006: // firebase故障
            return Promise.reject(error)
          case 50046: // 二维码已过期
            errMsg = 'codeExpired'
            break
          case 50007: // 已支付
            return Promise.resolve({
              isResolved: true,
              pathName: 'orderInvoicePaid'
            })
          case 50048: // 已关单
            errMsg = 'orderVoided'
            break
          default:
            errMsg = 'requestFail'
            break
        }

        return Promise.reject(new Error(errMsg))
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
