import { reactive } from 'vue'
import DataSource from './data-source/OrderedItemDataSource'
import OrderedItemType from './models/OrderedItemType'
import { buildItemIdentifier, buildModifierIdentifier } from './utils/item-identifier'
import { ItemUIType, ModifierItemUIType } from './models/OrderedItemUIModel'

function _formatNumber(num: number): number {
  return Math.round(num * 100) / 100
}

export default class OrderedItemManager {
  private readonly _itemMap: ItemUIType
  private readonly _modifierItemMap: ModifierItemUIType
  private readonly _submissionDineInOrToGo: { isToGo: boolean }
  private readonly _submissionNotes: { notes: string }

  constructor(dataSource: DataSource) {
    this._itemMap = reactive({})
    this._modifierItemMap = reactive({})
    this._submissionDineInOrToGo = reactive({ isToGo: false })
    this._submissionNotes = reactive({ notes: '' })
    dataSource.registerOrderedItemManager(this)
  }

  get itemMap(): ItemUIType {
    return this._itemMap
  }

  get modifierItemMap(): ModifierItemUIType {
    return this._modifierItemMap
  }

  get submissionDineInOrToGo(): { isToGo: boolean } {
    return this._submissionDineInOrToGo
  }

  get submissionNote(): { notes: string } {
    return this._submissionNotes
  }

  reset(): void {
    for (const itemIdentifier in this._itemMap) {
      delete this._itemMap[itemIdentifier]
    }

    for (const itemIdentifier in this._modifierItemMap) {
      delete this._modifierItemMap[itemIdentifier]
    }
  }

  updateSubmissionDineInOrToGo(isDineInOrToGo: boolean): void {
    this._submissionDineInOrToGo.isToGo = isDineInOrToGo
  }

  addItem(item: OrderedItemType): void {
    const modifierItemIdentifier = buildModifierIdentifier(item)

    this._modifierItemMap[modifierItemIdentifier] = item
    const itemIdentifier = buildItemIdentifier(item)
    const itemQuantity = this._itemMap[itemIdentifier]

    if (itemQuantity === undefined) {
      this._itemMap[itemIdentifier] = item.quantity
    } else {
      this._itemMap[itemIdentifier] = _formatNumber(itemQuantity + item.quantity)
    }
  }

  removeItem(item: OrderedItemType): void {
    const modifierItemIdentifier = buildModifierIdentifier(item)
    const modifierItemUIModel = this._modifierItemMap[modifierItemIdentifier]
    delete this._modifierItemMap[modifierItemIdentifier]

    if (modifierItemUIModel !== undefined) {
      const itemIdentifier = buildItemIdentifier(item)
      const itemQuantity = this._itemMap[itemIdentifier]

      if (itemQuantity !== undefined) {
        const updateQuantity = _formatNumber(itemQuantity - modifierItemUIModel.quantity)

        if (updateQuantity === 0) {
          delete this._itemMap[itemIdentifier]
        } else {
          this._itemMap[itemIdentifier] = updateQuantity
        }
      }
    }
  }

  updateItemQuantity(item: OrderedItemType, difference: number): void {
    const itemIdentifier = buildItemIdentifier(item)
    const itemQuantity = this._itemMap[itemIdentifier]

    if (itemQuantity !== undefined) {
      this._itemMap[itemIdentifier] = _formatNumber(itemQuantity + difference)
    }
  }

  updateSubmissionNotes(notes: string): void {
    this._submissionNotes.notes = notes
  }
}
