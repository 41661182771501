import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import fetchFirebaseToken from './api'

export default class FirebaseSignInManager {
  private static _manager?: FirebaseSignInManager

  private _customToken = ''
  set customToken (token: string) {
    this._customToken = token
    const auth = getAuth()
    signOut(auth).then(() => {
      /* console.info('signOut success') */
    }).catch(error => {
      this._errorCallback(error)
    })
  }

  private _isRunOnce: boolean
  private _error?: (error: { code: string, message: string }) => void
  private _completed?: () => void

  set callbacks ({
    isRunOnce,
    completed,
    error
  }: { isRunOnce: boolean, completed: () => void, error: (error: { code: string, message: string }) => void }) {
    this._isRunOnce = isRunOnce
    this._completed = completed
    this._error = error
  }

  static instance (): FirebaseSignInManager {
    if (this._manager === undefined) {
      this._manager = new FirebaseSignInManager()
    }

    return this._manager
  }

  get isFirebaseAuthorized (): boolean {
    return getAuth().currentUser !== null
  }

  private constructor () {
    this._isRunOnce = true
  }

  addAuthObserver (): void {
    const authentication = getAuth()
    authentication.onAuthStateChanged(user => {
      if (user) {
        this._completedCallback()
      } else {
        const customToken = this._customToken
        if (customToken === '') {
          fetchFirebaseToken().then(result => {
            this._signInWithCustomToken(result.data.token)
          }).catch(fetchError => {
            this._errorCallback({
              code: '',
              message: fetchError.message
            })
          })
        } else {
          this._signInWithCustomToken(customToken)
        }
      }
    })
  }

  private _updateCallbacks () {
    if (this._isRunOnce) {
      this._completed = undefined
      this._error = undefined
    }
  }

  private _completedCallback () {
    if (this._completed !== undefined) {
      this._completed()
      this._updateCallbacks()
    }
  }

  private _errorCallback (error: { code: string, message: string }) {
    if (this._error !== undefined) {
      this._error(error)
      this._updateCallbacks()
    }
  }

  private _signInWithCustomToken (token: string): void {
    signInWithCustomToken(getAuth(), token).then(() => {
      /* console.info('authorized success') */
    }).catch(signInError => {
      if (this._error !== undefined) {
        this._error({
          code: `${signInError.code}`,
          message: signInError.message
        })
      }
    })
  }
}
