import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchPcOnlineOrder } from '../api'
import OrderInformationManager from '@/utils/order-information-manager'
import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'
import LocalOrderedItemDataSource from '@/utils/ordered-item-manager/data-source/LocalOrderedItemDataSource'
import OrderedItemManager from '@/utils/ordered-item-manager'

export default class PcOnlineOrderPathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const { t, code } = this._pathQuery

      if (t !== undefined && code !== undefined) {
        const orderType = parseInt(t)
        if (orderType !== QRCodeType.PCOnlineOrder) {
          return Promise.resolve({ isResolved: false })
        } else {
          try {
            const { data } = await fetchPcOnlineOrder(code)
            const { onlineOrder, restaurantId: shopId } = data
            const { autoId, flatwareCount, note, onlineUser } = onlineOrder
            // 菜品初始化
            const localOrderedItemDataSource = LocalOrderedItemDataSource.instance()
            new OrderedItemManager(localOrderedItemDataSource)
            localOrderedItemDataSource.initialItems(
              onlineOrder.submission.items
            )

            const onlineDelivery =
              onlineOrder.onlineDelivery !== undefined
                ? onlineOrder.onlineDelivery
                : {
                    deliverFee: 0,
                    distance: 0,
                    fromCity: '',
                    fromState: '',
                    fromStreet: '',
                    fromZipcode: '',
                    isPickup: true,
                    scheduleTime: '',
                    scheduleTimestamp: 0,
                    toCity: '',
                    toState: '',
                    toStreet: '',
                    toZipcode: '',
                    expires: 0,
                    deliveryType: 1
                  }

            OrderInformationManager.instance().setSessionOnlineOrder({
              shopId,
              preOrder: OrderMenuPreOrderType.NoPreOrder,
              orderType: OrderMenuType.Online,
              isShowBack: true,
              autoId,
              flatwareCount,
              note,
              onlineDelivery,
              onlineUser,
              isPaid: false,
              guestCount: 1
            })

            return {
              isResolved: true,
              pathName: 'onlineOrderReview'
            }
          } catch (error) {
            if (
              `${(error as { message: string }).message}`.indexOf('50046') !==
              -1
            ) {
              return Promise.reject(new Error('qrCodePayExpired'))
            } else {
              return Promise.reject(error)
            }
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
