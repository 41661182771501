import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchQrCodeResult } from '../api'
import ResolveErrorType from '../models/ResolveErrorType'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
import { UserOrderType } from '@/views/app/utils/path-resolver/models/DineInOrderTableCheckInfo'
import LocalStorageManager from '@/utils/local-storage-manager'
import ShopAutoId from '@/utils/firebase-storage-manager/models/ShopAutoId'
import DineInOrderCheckModel from '../models/DineInOrderCheckModel'
import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import ShopOrderMode from '@/utils/shop-information-manager/models/ShopOrderMode'
import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import DineInOrToGo from '@/utils/pay-utils/models/order-models/DineInOrToGo'
import OrderSubmission from '@/views/order-information/models/OrderSubmission'
import { isDineInOrderProOrder } from '@/utils/dine-in-order-pre-order'
import OrderInformationManager from '@/utils/order-information-manager'
import PayCompleteManager from '@/views/pay-complete/models/PayCompleteManager'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import TimeManager from '@/network/utils/TimeManager'
import DineInOrderNodeBuilder from '@/firebase/firebase-node-builder/DineInOrderNodeBuilder'
import UserNodeBuilder from '@/firebase/firebase-node-builder/UserNodeBuilder'
import once from '@/utils/helper/once'
import GuestMobileOrderingMode from '@/utils/shop-information-manager/models/GuestMobileOrderingMode'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'
import { genFastOrderAutoId } from '@/utils/id-generator'
import { buildSessionDineOrder } from '@/utils/order-information-manager/utils/build-session-order-model'

export default class DineInOrderPathResolver extends Resolver {
  async _checkOrderIsExisted(userId: string, shopAutoId?: ShopAutoId): Promise<DineInOrderCheckModel | undefined> {
    if (shopAutoId !== undefined) {
      const {
        restaurantId: shopId,
        autoId
      } = shopAutoId
      const dineInOrderNodeBuilder = new DineInOrderNodeBuilder(shopId, autoId)
      const tableInfoNode = dineInOrderNodeBuilder.tableInfo()
      const userCountNode = dineInOrderNodeBuilder.userCount()
      const dineInOrToGoNode = dineInOrderNodeBuilder.dineInOrToGo()
      const submissionsNode = dineInOrderNodeBuilder.submissions()
      const orderPaymentsNode = dineInOrderNodeBuilder.isPaid()
      const result = await Promise.all([
        once(tableInfoNode),
        once(userCountNode),
        once(dineInOrToGoNode),
        once(submissionsNode),
        once(orderPaymentsNode)
      ])
      const firebaseTableInfo = result[0].val()
      const firebaseUserCount = result[1].val()
      const firebaseDineInOrToGo = result[2].val()
      const firebaseSubmissions = result[3].val()
      const guestPaymentIsPaid = result[4].val()

      if (firebaseTableInfo !== null && firebaseUserCount !== null) {
        const tableInfo = firebaseTableInfo as { tableGratuities: number, tableId: string, tableName: string }
        const userCount = firebaseUserCount as number
        const isDineIn = (firebaseDineInOrToGo as number) === DineInOrToGo.dineIn
        const submissions = firebaseSubmissions === null ? [] : firebaseSubmissions as OrderSubmission[]
        const preOrder = isDineInOrderProOrder(submissions)
        const isPaid = guestPaymentIsPaid !== null && guestPaymentIsPaid === true
        return Promise.resolve({
          tableInfo,
          userCount,
          autoId,
          isDineIn,
          preOrder,
          isPaid
        })
      } else {
        if (userId === '') {
          const localStorageManager = new LocalStorageManager()
          const userDineInOrders = localStorageManager.userDineInOrders
          localStorageManager.userDineInOrders = userDineInOrders.filter(userDineInOrder => userDineInOrder.autoId !== autoId || userDineInOrder.restaurantId !== shopId)
        }
      }
    }

    return Promise.resolve(undefined)
  }

  async _checkOrder(shopId: string): Promise<DineInOrderCheckModel | undefined> {
    const localStorageManager = new LocalStorageManager()
    const userId = localStorageManager.userId
    let shopAutoId: ShopAutoId | undefined

    if (userId === '') {
      const userDineInOrders = localStorageManager.userDineInOrders
      shopAutoId = userDineInOrders.find(({ restaurantId }) => restaurantId === shopId)
    } else {
      const userDineInOrderNode = new UserNodeBuilder(userId).dineInOrders()
      try {
        const firebaseData = await once(userDineInOrderNode)
        const dineInOrders = firebaseData.val()
        if (dineInOrders !== null) {
          const firebaseDineInOrders = dineInOrders as { [autoId: string]: { restaurantId: string, isOrderOwner: boolean } }
          for (const autoId in firebaseDineInOrders) {
            const { restaurantId } = firebaseDineInOrders[autoId]

            if (restaurantId === shopId) {
              shopAutoId = {
                restaurantId,
                autoId
              }
              break
            }
          }
        }
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.resolve(this._checkOrderIsExisted(userId, shopAutoId))
  }

  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const {
        t: orderType,
        rid: shopId,
        code,
        ts: timestamp
      } = this._pathQuery

      if (orderType !== undefined && shopId !== undefined && code !== undefined) {
        if (parseInt(orderType) !== QRCodeType.DineInOrder) {
          return Promise.resolve({ isResolved: false })
        } else {
          if (timestamp !== undefined && TimeManager.instance().currentMSTimestamp >= parseInt(timestamp) + 7200000) {
            return Promise.reject(new Error('codeExpired'))
          }
          try {
            const shopInformation = await ShopInformationManager.instance().fetchShopInformation(shopId)
            if (!shopInformation.posModule.isTableQRCodeEnabled) {
              return Promise.reject(new Error('tableQRCodeNotEnabled'))
            }

            const menuId = await currentMenuId(shopId, false)
            if (menuId === undefined) {
              RestaurantInfoManager.instance().setParams(shopId, '1')
              return Promise.resolve({
                isResolved: true,
                pathName: 'restaurantInformation'
              })
            }

            const dineInOrderCheckModel = await this._checkOrder(shopId)
            if (dineInOrderCheckModel !== undefined) {
              const isPaid = dineInOrderCheckModel.isPaid
              const tableInfo = dineInOrderCheckModel.tableInfo
              OrderInformationManager.instance().setSessionDineInOrder(buildSessionDineOrder(shopId,
                dineInOrderCheckModel.autoId, tableInfo.tableId, tableInfo.tableName, dineInOrderCheckModel.userCount,
                dineInOrderCheckModel.preOrder, dineInOrderCheckModel.isDineIn, true, false,
                tableInfo.tableGratuities, dineInOrderCheckModel.isPaid))

              if (!isPaid) {
                return Promise.resolve({
                  isResolved: true,
                  pathName: 'orderReviewInformation'
                })
              } else {
                PayCompleteManager.instance().setPayComplete(shopId, dineInOrderCheckModel.autoId, OrderMenuType.DineIn)
                return Promise.resolve({
                  isResolved: true,
                  pathName: 'payComplete'
                })
              }
            } else {
              const { data } = await fetchQrCodeResult(code)
              const { tableGratuities: dataTableGratuities, isTableGratuitiesEnabled } = data

              if (dataTableGratuities === -1 || !isTableGratuitiesEnabled) {
                data.tableGratuities = 0
              }
              const shopInformation = await ShopInformationManager.instance().fetchShopInformation(shopId)
              if (data.isEnabled) {
                const {
                  restaurantId: shopId,
                  tableId,
                  tableName,
                  tableGratuities
                } = data
                if (!shopInformation.paymentSetting.isCheckoutFirst) {
                  return Promise.resolve({
                    isResolved: true,
                    pathName: 'checkTableInfo',
                    params: {
                      shopId,
                      tableId,
                      tableName,
                      tableGratuities
                    }
                  })
                } else {
                  if (shopInformation.information.mode === ShopOrderMode.Table) {
                    return Promise.resolve({
                      isResolved: true,
                      pathName: 'newOrder',
                      params: {
                        shopId,
                        tableId,
                        tableName,
                        tableGratuities,
                        orderTye: `${UserOrderType.UserFastOrder}`
                      }
                    })
                  } else {
                    const guestMobileOrderingMode = shopInformation.mobileOrderSetting.guestMobileOrderingMode

                    if (guestMobileOrderingMode === GuestMobileOrderingMode.toGo || guestMobileOrderingMode === GuestMobileOrderingMode.dineIn) {
                      OrderInformationManager.instance().setSessionNoTableFastOrder({
                        shopId,
                        preOrder: OrderMenuPreOrderType.PreOrder,
                        isDineIn: guestMobileOrderingMode === GuestMobileOrderingMode.dineIn,
                        orderType: OrderMenuType.NoTableFast,
                        isShowBack: true,
                        autoId: genFastOrderAutoId(shopId),
                        isPaid: false
                      })
                      return {
                        isResolved: true,
                        pathName: 'shopOrderMenu'
                      }
                    } else {
                      return Promise.resolve({
                        isResolved: true,
                        pathName: 'quickServiceOrder',
                        params: {
                          shopId
                        }
                      })
                    }
                  }
                }
              } else {
                return Promise.reject(new Error(JSON.stringify({
                  type: QRCodeType.DineInOrder,
                  error: ResolveErrorType.QRCodeNotEnabled
                })))
              }
            }
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
    }
    return Promise.resolve({ isResolved: false })
  }
}
