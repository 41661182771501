import { defineComponent, ref, onMounted, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { Spin as ASpin, ConfigProvider as AConfigProvider, message, Modal } from 'ant-design-vue'
import indicator from '@/components/indicator'
import API from '@/network/API'
import { useRouter } from 'vue-router'
import MainLayout from '../../../components/main-layout/index.vue'
import LocalStorageManager from '@/utils/local-storage-manager'
import api from '@/views/giftcard-claim/api'
import UnclaimedGiftCardInfo from '@/views/giftcard-claim/models/UnclaimedGiftCardInfo'
import GiftcardClaim from '@/views/giftcard-claim/index.vue'
import QRCodeType from '../utils/path-resolver/models/QRCodeType'
import buildQueryParams from '../utils/query-params'
import initNextPage from '@/views/app/utils/init-next-page'
import RouterUtil from '@/utils/helper/RouterUtil'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import { FirebaseError, OrderError } from '@/network/error-code'
import { useGtag } from 'vue-gtag-next'

export default defineComponent({
  name: 'App',
  components: {
    ASpin,
    AConfigProvider,
    MainLayout,
    GiftcardClaim
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const { event } = useGtag()

    const isSpinRef = ref(false)
    provide('isSpinRef', isSpinRef)
    API.instance().isSpin = isSpinRef
    RouterUtil.instance().updateSpinRefAndRouter(isSpinRef, router)

    const unclaimedGiftCardsRef = ref<UnclaimedGiftCardInfo[]>([])

    // https://www.jianshu.com/p/073f79c5e438
    function getQueryParam(key: string): string {
      const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i')
      const r = window.location.search.substr(1).match(reg)
      return r ? r[2] : ''
    }

    async function fetchUnclaimedGiftCards() {
      try {
        const { data } = await api.unclaimedGiftCards()
        unclaimedGiftCardsRef.value = data
      } catch (error) {
        message.error(`error=${(error as { message: string }).message}`)
      }
    }

    async function fetchUnclaimedGiftCardsIfLogin() {
      if (!document.hidden && new LocalStorageManager().userId !== '') {
        // 如果是短信链接获取礼品卡url，或已经处于获取礼品卡页面，不调用fetchUnclaimedGiftCards
        if (Number(getQueryParam('t')) === QRCodeType.GiftCardFromFriend) {
          return
        }

        await fetchUnclaimedGiftCards()
      }
    }

    onMounted(async () => {
      // 监听页面可见性: https://bbs.huaweicloud.com/blogs/195247
      document.addEventListener('visibilitychange', fetchUnclaimedGiftCardsIfLogin, false)
      await fetchUnclaimedGiftCardsIfLogin()

      const queryParams = buildQueryParams()
      try {
        const {
          isResolved,
          pathName,
          params
        } = await initNextPage(queryParams)

        const currentEnvironment = process.env.VUE_APP_ENV

        if (isResolved) {
          if (currentEnvironment !== 'development' && currentEnvironment !== 'dev') {
            event('yammii_link_resolver', {
              event_category: 'entry',
              event_label: pathName
            })
          }
          await router.replace({
            name: pathName,
            params
          })
        } else {
          if (currentEnvironment !== 'development' && currentEnvironment !== 'dev') {
            event('yammii_link_resolver', {
              event_category: 'invalidLink',
              event_label: JSON.stringify(queryParams)
            })
          }
          message.error(t('invalidLink'))
          await RouterUtil.instance().replace({
            name: 'Home'
          })
        }
      } catch (error) {
        const e = error as { message: string, code: number }
        try {
          const errorObj = JSON.parse(e.message)
          if (errorObj.type === QRCodeType.MenuOrder) {
            message.error(t('menuQRCodeClosed'))
          } else if (errorObj.type === QRCodeType.OnlineOrder) {
            message.error(t('onlineQRCodeClosed'))
          } else if (errorObj.type === QRCodeType.Waitlist) {
            message.error(t('waitlistQRCodeClosed'))
          } else if (errorObj.type === QRCodeType.DineInOrder) {
            message.error(t('tableQRCodeNotClosed'))
          } else if (errorObj.type === QRCodeType.FastOrder) {
            message.error(t('quickServiceQRCodeClosed'))
          } else {
            message.error(t(e.message))
          }
        } catch (e1) {
          if (e.code === FirebaseError.FirebaseError) {
            Modal.warning({ title: t('communicationError'), content: t('firebaseError'), okText: t('close'), centered: true, width: 275 })
          } else if (e.code === OrderError.POSOffline) {
            const shopId = queryParams.rid

            if (shopId !== undefined) {
              RestaurantInfoManager.instance().setParams(shopId, '1')
              await RouterUtil.instance().replace({
                name: 'restaurantInformation'
              })
            } else {
              message.error(t(e.message))
            }
          } else {
            message.error(t(e.message))
          }
        }
      }
    })

    provide('fetchGiftCardFunc', fetchUnclaimedGiftCards)

    return {
      indicator,
      isSpinRef,
      unclaimedGiftCardsRef,
      t
    }
  }
})
