import { onValue, DatabaseReference, DataSnapshot } from 'firebase/database'

export default function once(reference: DatabaseReference): Promise<DataSnapshot> {
  return new Promise((resolve, reject) => {
    onValue(reference, dataSnapshot => {
      resolve(dataSnapshot)
    }, error => {
      reject(error)
    }, {
      onlyOnce: true
    })
  })
}
