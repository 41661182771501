import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_button = _resolveComponent("common-button")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_ctx.isImageBg)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.isSmallStyle ? 'small-style' : 'big-style', 'bg-image']),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickCell && _ctx.clickCell(...args)))
      }, [
        _withDirectives(_createElementVNode("img", {
          class: _normalizeClass([_ctx.isSmallStyle ? 'small-style' : 'big-style', 'card-img'])
        }, null, 2 /* CLASS */), [
          [_directive_lazy, _ctx.background]
        ]),
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.isSmallStyle ? 'font-24-bold-FFF' : 'font-34-bold-FFF', 'text-amount'])
        }, _toDisplayString(_ctx.cardAmount), 3 /* TEXT, CLASS */),
        (_ctx.isSendToFriend)
          ? (_openBlock(), _createBlock(_component_common_button, {
              key: 0,
              type: "primary",
              size: "large",
              width: "121px",
              class: "send-btn",
              onClick: _withModifiers(_ctx.send, ["stop"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('SendToFriend')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickCell && _ctx.clickCell(...args))),
        class: _normalizeClass([_ctx.isSmallStyle ? 'small-style' : 'big-style', 'bg-color']),
        style: _normalizeStyle(`background-color: ${_ctx.background}`)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.isSmallStyle ? 'font-18-bold-FFF' : 'font-24-bold-FFF', 'line-word-ellipsis-1'])
        }, _toDisplayString(_ctx.restaurantName), 3 /* TEXT, CLASS */),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.isSmallStyle ? 'font-12-FFF' : 'font-16-FFF'),
          style: _normalizeStyle(`margin-top: ${_ctx.isSmallStyle ? '10px' : '15px'}; margin-bottom: 6px`)
        }, _toDisplayString(_ctx.phoneNumber), 7 /* TEXT, CLASS, STYLE */),
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.isSmallStyle ? 'font-12-FFF' : 'font-16-FFF', 'line-word-ellipsis-2'])
        }, _toDisplayString(_ctx.address), 3 /* TEXT, CLASS */),
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.isSmallStyle ? 'font-24-bold-FFF' : 'font-34-bold-FFF', 'text-amount-pos'])
        }, _toDisplayString(_ctx.cardAmount), 3 /* TEXT, CLASS */),
        (_ctx.isSendToFriend)
          ? (_openBlock(), _createBlock(_component_common_button, {
              key: 0,
              type: "primary",
              size: "large",
              width: "121px",
              class: "send-btn",
              onClick: _withModifiers(_ctx.send, ["stop"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('SendToFriend')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]))
          : _createCommentVNode("v-if", true)
      ], 6 /* CLASS, STYLE */))
}