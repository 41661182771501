import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    class: _normalizeClass([{
    'custom-btn-color-00AAEF': _ctx.colorType === _ctx.ButtonColorType['#00AAEF'],
    'custom-btn-color-000000': _ctx.colorType === _ctx.ButtonColorType['#000000']
    }]),
    style: _normalizeStyle({ 'width': _ctx.width }),
    type: _ctx.type,
    size: _ctx.size
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "style", "type", "size"]))
}