import OrderedItemDataSource from './OrderedItemDataSource'
import OrderedItemManager from '../'
import OrderedItemType from '../models/OrderedItemType'
import { buildModifierIdentifier } from '../utils/item-identifier'
import { UnwrapNestedRefs } from '@vue/reactivity'
import { ItemUIType, ModifierItemUIType } from '../models/OrderedItemUIModel'
import { reactive } from 'vue'
import { OrderedItemObserver, ReviewMenuDataSource } from '@/utils/ordered-item-manager/data-source/ReviewMenuDataSource'
import SubmittedItemsTitle from '@/components/submitted-item-list/models/SubmittedItemsTitle'

export default class LocalOrderedItemDataSource implements OrderedItemDataSource, ReviewMenuDataSource {
  private static _dataSource: LocalOrderedItemDataSource

  static instance(): LocalOrderedItemDataSource {
    if (this._dataSource === undefined) {
      this._dataSource = new LocalOrderedItemDataSource()
    }

    return this._dataSource
  }

  private _orderedItemManager?: OrderedItemManager
  private _orderedItems: OrderedItemType[]
  private _submissionNotes: string
  private _orderedItemObservers: OrderedItemObserver[]

  private constructor() {
    this._orderedItems = []
    this._orderedItemObservers = []
    this._submissionNotes = ''
  }

  async reset(preOrderedItems: OrderedItemType[]): Promise<void> {
    const resetItems = () => {
      this._orderedItems = []
      // 菜品
      if (this._orderedItemManager !== undefined) {
        this._orderedItemManager.reset()
      }
    }

    if (preOrderedItems.length === 0) {
      resetItems()
    } else {
      resetItems()
      this.initialItems(preOrderedItems)
    }

    return Promise.resolve()
  }

  get orderedItems(): OrderedItemType[] {
    return this._orderedItems
  }

  get notes(): string {
    return this._submissionNotes
  }

  set orderedItemManager(manager: OrderedItemManager | undefined) {
    this._orderedItemManager = manager
  }

  get submissionDineInOrToGo(): boolean {
    if (this._orderedItemManager !== undefined) {
      return this._orderedItemManager.submissionDineInOrToGo.isToGo
    }

    return false
  }

  get itemMap(): UnwrapNestedRefs<ItemUIType> | undefined {
    if (this._orderedItemManager !== undefined) {
      return this._orderedItemManager.itemMap
    }
  }

  get modifierItemMap(): UnwrapNestedRefs<ModifierItemUIType> | undefined {
    if (this._orderedItemManager !== undefined) {
      return this._orderedItemManager.modifierItemMap
    }
  }

  registerOrderedItemManager(orderedItemManager: OrderedItemManager): void {
    this._orderedItemManager = orderedItemManager
  }

  async start(): Promise<void> {
    const orderedItems = this._orderedItems
    this._orderedItems = []
    orderedItems.forEach(orderedItem => {
      this._addItemRaw(orderedItem)
    })
    return Promise.resolve()
  }

  private _resetItems() {
    this._orderedItems = []
  }

  initialItems(items: OrderedItemType[]): void {
    if (this._orderedItems.length === 0) {
      items.forEach(item => {
        this._addItemRaw(item)
      })
    }
  }

  addOrderedItemObserver(orderedItemObserver: OrderedItemObserver): void {
    this._orderedItemObservers.push(orderedItemObserver)
  }

  stop(): void {
    this._orderedItemManager = undefined
    this._resetItems()
  }

  stopObserve(): void {
    this._orderedItemObservers.splice(0, this._orderedItemObservers.length)
  }

  startObserve(): void {
    const orderedItems = [{
      title: SubmittedItemsTitle.AllItems,
      submissionIndex: -1,
      items: this._orderedItems,
      notes: this._orderedItemManager?.submissionNote.notes ?? ''
    }]

    this._orderedItemObservers.forEach(orderedItemObserver => orderedItemObserver(orderedItems))
  }

  updateSubmissionNotes(notes: string): void {
    this._submissionNotes = notes
    if (this._orderedItemManager !== undefined) {
      this._orderedItemManager.updateSubmissionNotes(notes)
    }
  }

  addItem(item: OrderedItemType): void {
    const orderedItem = reactive(item)
    this._addItemRaw(orderedItem)
  }

  _addItemRaw(item: OrderedItemType): void {
    const orderedItem = item
    const modifierItemIdentifier = buildModifierIdentifier(item)
    const sameIdItems = this._orderedItems.filter(orderedItem => orderedItem.itemId === item.itemId)
    const validItem = sameIdItems.find(sameIdItem => buildModifierIdentifier(sameIdItem) === modifierItemIdentifier)

    if (validItem !== undefined) {
      validItem.quantity += 1

      if (this._orderedItemManager !== undefined) {
        this._orderedItemManager.updateItemQuantity(validItem, 1)
      }
    } else {
      this._orderedItems.push(orderedItem)

      if (this._orderedItemManager !== undefined) {
        this._orderedItemManager.addItem(orderedItem)
      }
    }
  }

  removeItem(item: OrderedItemType): void {
    const modifierItemIdentifier = buildModifierIdentifier(item)
    const itemIndex = this._orderedItems.findIndex(orderedItem => buildModifierIdentifier(orderedItem) === modifierItemIdentifier)

    if (itemIndex !== -1) {
      const validItem = this._orderedItems[itemIndex]
      const validItemQuantity = validItem.quantity
      const updateQuantity = validItemQuantity - 1

      if (updateQuantity === 0) {
        this._orderedItems.splice(itemIndex, 1)

        if (this._orderedItemManager !== undefined) {
          this._orderedItemManager.removeItem(item)
        }
      } else {
        validItem.quantity = updateQuantity

        if (this._orderedItemManager !== undefined) {
          this._orderedItemManager.updateItemQuantity(validItem, -1)
        }
      }
    }
  }

  removeItemWithItemId(itemId: string): boolean {
    const validItems = this._orderedItems.filter(orderedItem => orderedItem.itemId === itemId)
    if (validItems.length === 0) {
      return false
    } else {
      const itemIndex = this._orderedItems.findIndex(orderedItem => orderedItem.itemId === itemId)
      const validItem = this._orderedItems[itemIndex]
      const validItemQuantity = validItem.quantity
      const updateQuantity = validItemQuantity - 1

      if (updateQuantity === 0) {
        this._orderedItems.splice(itemIndex, 1)

        if (this._orderedItemManager !== undefined) {
          this._orderedItemManager.removeItem(validItem)
        }
      } else {
        validItem.quantity = updateQuantity

        if (this._orderedItemManager !== undefined) {
          this._orderedItemManager.updateItemQuantity(validItem, -1)
        }
      }
      return true
    }
  }

  updateItem(item: OrderedItemType, quantity: number): void {
    const modifierItemIdentifier = buildModifierIdentifier(item)

    if (quantity === 0) {
      const itemIndex = this._orderedItems.findIndex(orderedItem => buildModifierIdentifier(orderedItem) === modifierItemIdentifier)

      if (itemIndex !== -1) {
        this._orderedItems.splice(itemIndex, 1)

        if (this._orderedItemManager !== undefined) {
          this._orderedItemManager.removeItem(item)
        }
      }
    } else {
      const validItems = this._orderedItems.filter(orderedItem => orderedItem.itemId === item.itemId)
      const validItem = validItems.find(validItem => buildModifierIdentifier(validItem) === modifierItemIdentifier)

      if (validItem !== undefined) {
        validItem.quantity = quantity
        const difference = item.quantity - quantity

        if (this._orderedItemManager !== undefined) {
          this._orderedItemManager.updateItemQuantity(validItem, difference)
        }
      } else {
        item.quantity = quantity
        this.addItem(item)
      }
    }
  }

  updateItemNotes(item: OrderedItemType, notes: string): void {
    const modifierItemIdentifier = buildModifierIdentifier(item)
    const validItems = this._orderedItems.filter(orderedItem => orderedItem.itemId === item.itemId)
    const validItem = validItems.find(validItem => buildModifierIdentifier(validItem) === modifierItemIdentifier)

    if (validItem !== undefined) {
      if (notes.trim() !== '') {
        validItem.notes = notes
      } else {
        delete validItem.notes
      }
    }
  }

  updateDineInOrToGo(isDineInOrToGo: boolean): void {
    if (this._orderedItemManager !== undefined) {
      this._orderedItemManager.updateSubmissionDineInOrToGo(isDineInOrToGo)
    }
  }
}
