import Resolver from '@/views/app/utils/path-resolver/resolvers/Resolver'
import ResolveResult from '@/views/app/utils/path-resolver/resolvers/ResolveResult'
import QRCodeType from '@/views/app/utils/path-resolver/models/QRCodeType'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'

export default class ShopInformationPathResolver extends Resolver {
  resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const { t: type, rid: shopId } = this._pathQuery

      if (type !== undefined && shopId !== undefined) {
        if (parseInt(type) !== QRCodeType.ShopDetail) {
          return Promise.resolve({ isResolved: false })
        } else {
          RestaurantInfoManager.instance().setParams(shopId, '0')
          return Promise.resolve({
            isResolved: true,
            pathName: 'restaurantInformation'
          })
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
