import Resolver from '@/views/app/utils/path-resolver/resolvers/Resolver'
import ResolveResult from '@/views/app/utils/path-resolver/resolvers/ResolveResult'
import { fetchShopIdByDomain } from '@/views/app/utils/path-resolver/api'
import ShopIdManager from '@/utils/shop-id-manager'

export default class OrderFunctionsPathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    const pathQuery = this._pathQuery

    if (pathQuery !== undefined) {
      const shopName = pathQuery.shop_name

      if (shopName !== undefined) {
        try {
          let shopId = shopName
          if (!/^\d{19}$/.test(shopName)) {
            const { data } = await fetchShopIdByDomain(shopId)
            shopId = data.id
          }

          if (shopId !== undefined) {
            ShopIdManager.instance().extras.shopId = shopId
            ShopIdManager.instance().extras.scan = pathQuery.scan

            return Promise.resolve({
              isResolved: true,
              pathName: 'orderFunctions'
            })
          }
        } catch (e) {
          return Promise.reject(e)
        }
      }
    }
    return Promise.resolve({
      isResolved: false
    })
  }
}
