import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_giftcard_claim = _resolveComponent("giftcard-claim")!
  const _component_main_layout = _resolveComponent("main-layout")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    spinning: _ctx.isSpinRef,
    indicator: _ctx.indicator,
    tip: _ctx.t('loading'),
    wrapperClassName: _ctx.isSpinRef ? 'ant-spin-loading-custom-class' : ''
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_config_provider, { autoInsertSpaceInButton: false }, {
        default: _withCtx(() => [
          _createVNode(_component_main_layout, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view),
              _createVNode(_component_giftcard_claim, {
                cardInfos: _ctx.unclaimedGiftCardsRef,
                "onUpdate:cardInfos": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.unclaimedGiftCardsRef) = $event))
              }, null, 8 /* PROPS */, ["cardInfos"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["spinning", "indicator", "tip", "wrapperClassName"]))
}