export default class TimeManager {
  private static _timeManager: TimeManager
  private _offset: number | undefined = undefined

  static instance () : TimeManager {
    if (this._timeManager === undefined) {
      this._timeManager = new TimeManager()
    }

    return this._timeManager
  }

  get offset (): number | undefined {
    return this._offset
  }

  get currentMSTimestamp (): number {
    return (this._offset ?? 0) + Date.now()
  }

  sync (responseDate?: string): void {
    if (this._offset === undefined && responseDate !== undefined) {
      const serverTimestamp = Date.parse(responseDate)
      this._offset = serverTimestamp - Date.now().valueOf()
    }
  }
}
