import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchQrCodeResult } from '../api'
import ResolveErrorType from '../models/ResolveErrorType'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'
import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import TimeManager from '@/network/utils/TimeManager'
import GuestMobileOrderingMode from '@/utils/shop-information-manager/models/GuestMobileOrderingMode'
import OrderInformationManager from '@/utils/order-information-manager'
import OrderMenuPreOrderType from '@/utils/order-information-manager/models/OrderMenuPreOrderType'
import OrderMenuType from '@/utils/order-information-manager/models/OrderMenuType'
import { genFastOrderAutoId } from '@/utils/id-generator'

export default class FastOrderPathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const {
        t: orderType,
        rid: shopId,
        code,
        ts: timestamp
      } = this._pathQuery

      if (orderType !== undefined && shopId !== undefined && code !== undefined) {
        if (parseInt(orderType) !== QRCodeType.FastOrder) {
          return Promise.resolve({ isResolved: false })
        } else {
          if (timestamp !== undefined && TimeManager.instance().currentMSTimestamp >= parseInt(timestamp) + 7200000) {
            return Promise.reject(new Error('codeExpired'))
          }
          try {
            const shopInformation = await ShopInformationManager.instance().fetchShopInformation(shopId)
            if (!shopInformation.posModule.isTableQRCodeEnabled) {
              return Promise.reject(new Error('tableQRCodeNotEnabled'))
            }

            const menuId = await currentMenuId(shopId, false)
            if (menuId === undefined) {
              RestaurantInfoManager.instance().setParams(shopId, '1')
              return Promise.resolve({
                isResolved: true,
                pathName: 'restaurantInformation'
              })
            }
            const { data } = await fetchQrCodeResult(code)
            const {
              isEnabled
            } = data

            if (isEnabled) {
              const guestMobileOrderingMode = shopInformation.mobileOrderSetting.guestMobileOrderingMode

              if (guestMobileOrderingMode === GuestMobileOrderingMode.toGo || guestMobileOrderingMode === GuestMobileOrderingMode.dineIn) {
                OrderInformationManager.instance().setSessionNoTableFastOrder({
                  shopId,
                  preOrder: OrderMenuPreOrderType.PreOrder,
                  isDineIn: guestMobileOrderingMode === GuestMobileOrderingMode.dineIn,
                  orderType: OrderMenuType.NoTableFast,
                  isShowBack: true,
                  autoId: genFastOrderAutoId(shopId),
                  isPaid: false
                })
                return {
                  isResolved: true,
                  pathName: 'shopOrderMenu'
                }
              } else {
                return {
                  isResolved: true,
                  pathName: 'quickServiceOrder',
                  params: { shopId }
                }
              }
            }
            return Promise.reject(new Error(JSON.stringify({
              type: QRCodeType.FastOrder,
              error: ResolveErrorType.QRCodeNotEnabled
            })))
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
