import { ref, child, getDatabase, push } from 'firebase/database'

function _genOrderAutoId(shopId: string, orderTypeName: string): string {
  const dineInOrderNode = child(ref(getDatabase(), orderTypeName), shopId)
  return push(dineInOrderNode).key as string
}

function genDineInOrderAutoId(shopId: string): string {
  return _genOrderAutoId(shopId, 'dineInOrders')
}

function genFastOrderAutoId(shopId: string): string {
  return _genOrderAutoId(shopId, 'fastOrders')
}

function genOnlineOrderAutoId(shopId: string): string {
  return _genOrderAutoId(shopId, 'onlineOrders')
}
function genPaymentOrderAutoId(shopId: string): string {
  return _genOrderAutoId(shopId, 'paymentOrders')
}

export { genDineInOrderAutoId, genFastOrderAutoId, genOnlineOrderAutoId, genPaymentOrderAutoId }
