import { initializeApp } from 'firebase/app'

export default function initializeFirebaseApp (): void {
  if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENV === 'dev') {
    const firebaseConfig = {
      apiKey: 'AIzaSyCwUiFWuk2HR5_XDfh1RcpaoxVukapTBA8',
      authDomain: 'yammii-test.firebaseapp.com',
      databaseURL: 'https://yammii-test.firebaseio.com',
      projectId: 'yammii-test',
      storageBucket: 'yammii-test.appspot.com',
      messagingSenderId: '642914082871'
    }
    initializeApp(firebaseConfig)
  } else {
    const firebaseConfig = {
      apiKey: 'AIzaSyBSJbngoxjE_RR6PYO2j49t8x9QlS6mUN8',
      authDomain: 'yammii.firebaseapp.com',
      databaseURL: 'https://yammii.firebaseio.com',
      projectId: 'yammii',
      storageBucket: 'yammii.appspot.com',
      messagingSenderId: '762514857020'
    }
    initializeApp(firebaseConfig)
  }
}
