import { defineComponent, ref, PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import PageLayout from '@/components/page-layout/index.vue'
import PageNav from '@/components/page-nav/index.vue'
import GiftcardAccept from '@/components/giftcard-accept/index.vue'
import CommonModal from '@/components/common-modal/index.vue'
import CommonButton from '@/components/common-button/index.vue'
import UnclaimedGiftCardInfo from '../models/UnclaimedGiftCardInfo'
import api from '../api'
import { waitMoment } from '@/utils/helper'
import RouterUtil from '@/utils/helper/RouterUtil'

export default defineComponent({
  name: 'GiftcardClaim',

  components: {
    PageLayout,
    PageNav,
    GiftcardAccept,
    CommonModal,
    CommonButton
  },

  props: {
    cardInfos: {
      type: Array as PropType<UnclaimedGiftCardInfo[]>,
      default: () => []
    }
  },
  emits: ['update:cardInfos'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const route = useRoute()

    const giftCardInfo: UnclaimedGiftCardInfo | undefined = route.params.giftCardInfo
      ? JSON.parse(route.params.giftCardInfo as string)
      : undefined

    const refuseVisibleRef = ref<boolean>(false)
    const showCardRef = ref<boolean>(true)

    const unclaimedGiftCardInfo = computed(() => props.cardInfos.length > 0 ? props.cardInfos[props.cardInfos.length - 1] : undefined)

    function refuse() {
      refuseVisibleRef.value = true
    }

    function confirmRefuse() {
      refuseVisibleRef.value = false
      claim(false)
    }

    async function claim(approved: boolean) {
      try {
        if (giftCardInfo) {
          await api.acceptOrRefuseGiftCard(giftCardInfo.giveId, approved)
        } else if (unclaimedGiftCardInfo.value) {
          await api.claimGiftCard(unclaimedGiftCardInfo.value.giveId, approved)
        }

        message.success(t(approved ? 'ReceivedSuccess' : 'RefusedSuccess'))

        if (giftCardInfo) {
          await RouterUtil.instance().replace('/')
        } else {
          showCardRef.value = false

          const hasExtraCard = props.cardInfos.length > 1
          emit('update:cardInfos', props.cardInfos.slice(0, -1))

          if (hasExtraCard) {
            await waitMoment(300)
          }

          showCardRef.value = true
        }
      } catch (error) {
        message.error(t('requestFail', [(error as { message: string }).message]))
      }
    }

    return {
      t,
      giftCardInfo,
      refuseVisibleRef,
      showCardRef,
      unclaimedGiftCardInfo,
      refuse,
      confirmRefuse,
      claim
    }
  }
})
