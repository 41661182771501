import ResolverManager from '../path-resolver'
import GiftCardPathResolver from '../path-resolver/resolvers/GiftCardPathResolver'
import SharePathResolver from '../path-resolver/resolvers/SharePathResolver'
import WaitlistPathResolver from '../path-resolver/resolvers/WaitlistPathResolver'
import OnlineOrderPathResolver from '../path-resolver/resolvers/OnlineOrderPathResolver'
import DineInOrderPathResolver from '../path-resolver/resolvers/DineInOrderPathResolver'
import FastOrderPathResolver from '../path-resolver/resolvers/FastOrderPathResolver'
import MenuOrderPathResolver from '../path-resolver/resolvers/MenuOrderPathResolver'
import ScanPayResolver from '../path-resolver/resolvers/ScanPayResolver'
import PcOnlineOrderPathResolver from '../path-resolver/resolvers/PcOnlineOrderPathResolver'
import OnlineOrderDomainResolver from '../path-resolver/resolvers/OnlineOrderDomainResolver'
import ShopInformationPathResolver from '../path-resolver/resolvers/ShopInformationPathResolver'
import ResolveResult from '../path-resolver/resolvers/ResolveResult'
import OrderFunctionsPathResolver from '@/views/app/utils/path-resolver/resolvers/OrderFunctionsPathResolver'
import WaitlistDetailPathResolver from '@/views/app/utils/path-resolver/resolvers/WaitlistDetailPathResolver'

export default async function initNextPage(query: { [key: string]: string }): Promise<ResolveResult> {
  const resolverManager = new ResolverManager(query)
  new ShopInformationPathResolver(resolverManager)
  new GiftCardPathResolver(resolverManager)
  new SharePathResolver(resolverManager)
  new WaitlistPathResolver(resolverManager)
  new WaitlistDetailPathResolver(resolverManager)
  new OnlineOrderPathResolver(resolverManager)
  new DineInOrderPathResolver(resolverManager)
  new FastOrderPathResolver(resolverManager)
  new MenuOrderPathResolver(resolverManager)
  new ScanPayResolver(resolverManager)
  new PcOnlineOrderPathResolver(resolverManager)
  new OrderFunctionsPathResolver(resolverManager)
  new OnlineOrderDomainResolver(resolverManager)

  try {
    const resolveResult = await resolverManager.resolve()
    return Promise.resolve(resolveResult)
  } catch (e) {
    return Promise.reject(e)
  }
}
