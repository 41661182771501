import Resolver from './Resolver'
import ResolveResult from './ResolveResult'
import QRCodeType from '../models/QRCodeType'
import { fetchMenuQRCodeMenuId } from '../api'
import ResolveErrorType from '../models/ResolveErrorType'
import OrderInformationManager from '@/utils/order-information-manager'
import { currentMenuId } from '@/utils/menu-manager/utils/menu-builder'
import { genFastOrderAutoId } from '@/utils/id-generator'
import RestaurantInfoManager from '@/views/restaurant-information/utils/restaurant-info-manager'
import TimeManager from '@/network/utils/TimeManager'
import {
  buildSessionMenuOrder,
  buildSessionViewMenuOrder
} from '@/utils/order-information-manager/utils/build-session-order-model'
import ShopInformationManager from '@/utils/shop-information-manager/ShopInformationManager'

export default class MenuOrderPathResolver extends Resolver {
  async resolvePath(): Promise<ResolveResult> {
    if (this._pathQuery !== undefined) {
      const {
        t,
        rid: shopId,
        code,
        ts: timestamp
      } = this._pathQuery

      if (t !== undefined && shopId !== undefined && code !== undefined) {
        const orderType = parseInt(t)
        if (orderType !== QRCodeType.MenuOrder && orderType !== QRCodeType.ViewMenu) {
          return Promise.resolve({ isResolved: false })
        } else {
          if (timestamp !== undefined && TimeManager.instance().currentMSTimestamp >= parseInt(timestamp) + 7200000) {
            return Promise.reject(new Error('codeExpired'))
          }
          try {
            const { data } = await fetchMenuQRCodeMenuId(code)
            const {
              menuId,
              isEnabled
            } = data

            if (isEnabled) {
              if (orderType === QRCodeType.ViewMenu) {
                OrderInformationManager.instance().setSessionViewMenuOrder(buildSessionViewMenuOrder(shopId, menuId))
              } else {
                const information = await ShopInformationManager.instance().fetchShopInformation(shopId)
                const paymentSetting = information.paymentSetting
                const { isYammiiPaymentEnabled, isMobilePaymentEnabled } = paymentSetting

                if (!isYammiiPaymentEnabled || !isMobilePaymentEnabled) {
                  RestaurantInfoManager.instance().setParams(shopId, '1')
                  return Promise.resolve({
                    isResolved: true,
                    pathName: 'restaurantInformation'
                  })
                }

                const validMenuId = await currentMenuId(shopId, false)
                if (validMenuId === undefined) {
                  RestaurantInfoManager.instance().setParams(shopId, '1')
                  return Promise.resolve({
                    isResolved: true,
                    pathName: 'restaurantInformation'
                  })
                }

                const autoId = genFastOrderAutoId(shopId)
                OrderInformationManager.instance().setSessionMenuOrder(buildSessionMenuOrder(shopId, menuId, autoId))
              }

              return {
                isResolved: true,
                pathName: 'shopOrderMenu'
              }
            }
            return Promise.reject(new Error(JSON.stringify({
              type: orderType,
              error: ResolveErrorType.QRCodeNotEnabled
            })))
          } catch (e) {
            return Promise.reject(e)
          }
        }
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
