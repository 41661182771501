import { getDatabase, ref, Database, DatabaseReference, child } from 'firebase/database'

export default class UserNodeBuilder {
  private readonly _userId: string
  private readonly _db: Database

  constructor(userId: string) {
    this._userId = userId
    this._db = getDatabase()
  }

  _users(): DatabaseReference {
    return ref(this._db, 'users')
  }

  userId(): DatabaseReference {
    return child(this._users(), this._userId)
  }

  dineInOrders(): DatabaseReference {
    return child(this.userId(), 'dineInOrders')
  }

  fastOrders(): DatabaseReference {
    return child(this.userId(), 'fastOrders')
  }

  waitlists(): DatabaseReference {
    return child(this.userId(), 'waitlists')
  }

  onlineOrders(): DatabaseReference {
    return child(this.userId(), 'onlineOrders')
  }
}
