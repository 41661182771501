import { Item } from '@/utils/menu-manager/models/item'
import Modifier from '@/utils/menu-manager/models/menu/models/Modifier'
import Option from '@/utils/menu-manager/models/menu/models/Option'
import Category from '@/utils/menu-manager/models/menu/models/Category'
import globalI18n from '@/i18n'
import Menu from '@/utils/menu-manager/models/menu/models/Menu'
import { LangType } from '@/views/shop-order-menu/components/menu-items/utils/language-options'

const LangMap: { [locale: string]: string } = {
  // eslint-disable-next-line camelcase
  zh_CN: 'zh-CN',
  // eslint-disable-next-line camelcase
  en_US: 'en-US',
  // eslint-disable-next-line camelcase
  es_ES: 'es-US'
}

let _isLangEnabled = false
const _langEnabledMap: { [key: string]: boolean } = {}

function getIsLangEnabled(shopId: string): void {
  _isLangEnabled = _langEnabledMap[shopId]
}

function updateIsLangEnabled(isLangEnabled: boolean, shopId: string): void {
  _isLangEnabled = isLangEnabled
  _langEnabledMap[shopId] = isLangEnabled
}

function currentLang(type: number): 'en_US' | 'zh_CN' | 'es_ES' {
  // const currentLang = globalI18n.global.locale.value
 
  if (type === LangType.Chinese) {
    return 'zh_CN'
  }

  if (type === LangType.English) {
    return 'en_US'
  }

  if (type === LangType.Spanish) {
    return 'es_ES'
  }

  return 'en_US'
}

function fetchName(name: string, translations: { [locale: string]: string }): string {
  if (_isLangEnabled) {
    const locale = globalI18n.global.locale.value
    const localeLang = LangMap[locale]

    if (localeLang !== undefined && translations !== undefined && translations !== null) {
      const localeName = translations[localeLang]

      if (localeName !== undefined && localeName.trim() !== '') {
        return localeName
      }
    }
  }

  return name
}

function buildItemName(item: Item): string {
  return fetchName(item.name, item.translations)
}

function buildModifierName(modifier: Modifier): string {
  return fetchName(modifier.name, modifier.translations)
}

function buildOptionName(option: Option): string {
  return fetchName(option.name, option.translations)
}

function buildIngredientName(item: Item): string {
  return fetchName(item.ingredient, item.ingredientTranslations)
}

function buildDescriptionName(item: Item): string {
  return fetchName(item.descriptions, item.descriptionsTranslations)
}

function buildMenuName(menu: Menu): string {
  return fetchName(menu.menuName, menu.translations)
}

function buildCategoryName(category: Category): string {
  return fetchName(category.name, category.translations)
}

export {
  fetchName,
  buildItemName,
  buildModifierName,
  buildOptionName,
  buildIngredientName,
  buildDescriptionName,
  buildMenuName,
  buildCategoryName,
  currentLang,
  updateIsLangEnabled,
  getIsLangEnabled,
  LangMap
}
