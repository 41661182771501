import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import CommonButton from '@/components/common-button/index.vue'
import MyGiftCardInfo from '@/views/giftcard-list/models/MyGiftCardInfo'
import { formatPhone, formatAddress, formatNumber } from '@/utils/helper'

export default defineComponent({
  name: 'GiftCardCell',

  components: {
    CommonButton
  },

  props: {
    item: {
      type: Object as PropType<MyGiftCardInfo>,
      required: true
    },
    isSmallStyle: {
      type: Boolean,
      required: true
    },
    canPress: {
      type: Boolean,
      default: false
    },
    isSendToFriend: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click', 'sendToFriend'],

  setup(props, { emit }) {
    const { t } = useI18n()

    const isImageBg = computed<boolean>(() => props.item.giftCardType.cardType === 2)
    const background = computed<string>(() => props.item.giftCardType.background)
    const restaurantName = computed<string | undefined>(() => props.item.shopInfo?.restaurantName)
    const phoneNumber = computed<string>(() => formatPhone(props.item.shopInfo?.phoneNumber))
    const address = computed<string>(() => formatAddress(props.item.shopInfo))
    const cardAmount = computed<string>(() => `$${formatNumber(props.item.giftCardInfo.amount)}`)

    function clickCell() {
      if (props.canPress) {
        emit('click', props.item.giftCardInfo)
      }
    }

    function send() {
      emit('sendToFriend', props.item.giftCardInfo.giftCardId)
    }

    return {
      t,
      ...props,
      isImageBg,
      background,
      restaurantName,
      phoneNumber,
      address,
      cardAmount,
      clickCell,
      send
    }
  }
})
