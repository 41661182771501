import Resolver from './resolvers/Resolver'
import ResolveResult from './resolvers/ResolveResult'

export default class ResolverManager {
  private _resolvers: Resolver[] = []
  private readonly _pathQuery: { [key: string]: string }

  constructor (pathQuery: { [key: string]: string }) {
    this._pathQuery = pathQuery
  }

  addResolver (resolver: Resolver): void {
    resolver.pathQuery = this._pathQuery
    this._resolvers.push(resolver)
    const resolversLength = this._resolvers.length

    if (resolversLength > 1) {
      this._resolvers[resolversLength - 2].nextResolver = resolver
    }
  }

  async resolve (): Promise<ResolveResult> {
    if (this._resolvers.length > 0) {
      try {
        const result = await this._resolvers[0].resolve()
        return Promise.resolve(result)
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.resolve({ isResolved: false })
  }
}
