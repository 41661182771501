export default class ShopIdManager {
  private static _manager: ShopIdManager

  static instance(): ShopIdManager {
    if (this._manager === undefined) {
      this._manager = new ShopIdManager()
    }

    return this._manager
  }

  private constructor() {
    this.extras = {}
  }

  extras: { [key: string]: string }
}
