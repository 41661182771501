import UserInformation from './models/UserInformation'
import { fetchUserInformation, fetchUserShopPoints } from './api'
import UserShopPoints from './models/UserShopPoints'

export default class AccountManager {
  private static _manager?: AccountManager

  static instance(): AccountManager {
    if (this._manager === undefined) {
      this._manager = new AccountManager()
    }

    return this._manager
  }

  private _userInformation?: UserInformation

  private constructor() {
    this._userInformation = undefined
  }

  set userInformation(information: UserInformation) {
    this._userInformation = information
  }

  async fetchUserInformation(): Promise<UserInformation> {
    if (this._userInformation !== undefined) {
      return Promise.resolve(this._userInformation)
    } else {
      try {
        const { data } = await fetchUserInformation()
        const { id, name, phoneNumber: phone, email, address: userAddresses } = data
        this._userInformation = { id, name, phone, email, userAddresses }
        return Promise.resolve(this._userInformation)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }

  private _userShopPoints: { [shopId: string]: UserShopPoints } = {}

  async fetchUserShopPoints(shopId: string): Promise<UserShopPoints> {
    const userShopPoints = this._userShopPoints[shopId]

    if (userShopPoints !== undefined) {
      return Promise.resolve(userShopPoints)
    } else {
      try {
        const { data } = await fetchUserShopPoints(shopId)
        this._userShopPoints[shopId] = data
        return Promise.resolve(data)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }

  reset(): void {
    this._userShopPoints = {}
    this._userInformation = undefined
  }
}
