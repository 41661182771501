import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2faf84d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "giftcard-accept" }
const _hoisted_2 = { class: "font-18-bold-333 title-text" }
const _hoisted_3 = { class: "font-16-bold-333 sender-text" }
const _hoisted_4 = {
  key: 0,
  class: "font-16-333",
  style: {"margin-top":"15px"}
}
const _hoisted_5 = {
  class: "font-14-999",
  style: {"margin-top":"15px"}
}
const _hoisted_6 = {
  key: 1,
  class: "font-14-999",
  style: {"margin-top":"10px"}
}
const _hoisted_7 = {
  key: 3,
  class: "btn-view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_giftcard_cell = _resolveComponent("giftcard-cell")!
  const _component_common_button = _resolveComponent("common-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t(_ctx.fromRestaurant ? 'ReceivedGiftCard' : 'HaveGiftCard')), 1 /* TEXT */),
    _createVNode(_component_giftcard_cell, {
      class: "card",
      item: _ctx.giftCardInfo,
      isSmallStyle: ""
    }, null, 8 /* PROPS */, ["item"]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Sender', [_ctx.item.sourceName])), 1 /* TEXT */),
    (_ctx.item.message !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.item.message), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('IssuedDate', [_ctx.item.givingDate])), 1 /* TEXT */),
    (!_ctx.fromRestaurant)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t('Expires', [_ctx.item.expireDate])), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    (_ctx.fromRestaurant)
      ? (_openBlock(), _createBlock(_component_common_button, {
          key: 2,
          class: "close-btn",
          width: "100%",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.acceptOrRefuse(true)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('Close')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_common_button, {
            class: "refuse-btn",
            type: "dashed",
            width: "33.9%",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.acceptOrRefuse(false)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Refuse')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_common_button, {
            width: "66.1%",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.acceptOrRefuse(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Accept')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]))
  ]))
}