import { getDatabase, ref, Database, DatabaseReference, child } from 'firebase/database'

export default class DineInOrderNodeBuilder {
  private readonly _shopId: string
  private readonly _autoId: string
  private readonly _db: Database

  constructor(shopId: string, autoId: string) {
    this._shopId = shopId
    this._autoId = autoId
    this._db = getDatabase()
  }

  orderNode(): DatabaseReference {
    return child(child(ref(this._db, 'dineInOrders'), this._shopId), this._autoId)
  }

  createTime(): DatabaseReference {
    return child(this.orderNode(), 'createTime')
  }

  orderNumber(): DatabaseReference {
    return child(this.orderNode(), 'orderNumber')
  }

  serialNumber(): DatabaseReference {
    return child(this.orderNode(), 'serialNumber')
  }

  submissions(): DatabaseReference {
    return child(this.orderNode(), 'submissions')
  }

  orderPayments(): DatabaseReference {
    return child(this.orderNode(), 'orderPayments')
  }

  tableInfo(): DatabaseReference {
    return child(this.orderNode(), 'tableInfo')
  }

  tableName(): DatabaseReference {
    return child(this.tableInfo(), 'tableName')
  }

  tableId(): DatabaseReference {
    return child(this.tableInfo(), 'tableId')
  }

  tableGratuities(): DatabaseReference {
    return child(this.tableInfo(), 'tableGratuities')
  }

  userCount(): DatabaseReference {
    return child(this.orderNode(), 'userCount')
  }

  dineInOrToGo(): DatabaseReference {
    return child(this.orderNode(), 'dineInOrToGo')
  }

  submission(index: number): DatabaseReference {
    return child(this.submissions(), `${index}`)
  }

  isSubmitted(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'isSubmitted')
  }

  submissionNotes(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'notes')
  }

  submissionItems(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'items')
  }

  submissionDineInOrTo(index: number): DatabaseReference {
    return child(child(this.submissions(), `${index}`), 'dineInOrToGo')
  }

  orderCombineCreateTime(): DatabaseReference {
    return child(child(this.orderNode(), 'orderCombine'), 'createTime')
  }

  isOrderSplit(): DatabaseReference {
    return child(this.orderNode(), 'isOrderSplit')
  }

  guestPayments(): DatabaseReference {
    return child(child(this.orderPayments(), '0'), 'guestPayments')
  }

  guestPayment(): DatabaseReference {
    return child(this.guestPayments(), '0')
  }

  isPaid(): DatabaseReference {
    return child(this.guestPayment(), 'isPaid')
  }

  total(): DatabaseReference {
    return child(this.guestPayment(), 'total')
  }

  tax(): DatabaseReference {
    return child(this.guestPayment(), 'tax')
  }

  tips(): DatabaseReference {
    return child(this.guestPayment(), 'tips')
  }

  gratuity(): DatabaseReference {
    return child(this.guestPayment(), 'gratuity')
  }

  serviceFee(): DatabaseReference {
    return child(this.guestPayment(), 'serviceFee')
  }

  payAmount(): DatabaseReference {
    return child(this.guestPayment(), 'payAmount')
  }

  payMethod(): DatabaseReference {
    return child(this.guestPayment(), 'payMethod')
  }
}
